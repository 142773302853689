import React, { useState, useRef } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import "./index.css";
import { useTranslation } from "react-i18next";

export default function SearchInput(props) {
  const [selectedInput, setSelectedInput] = useState(false);
  const isMobile = window.matchMedia("(max-width: 768px)");
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const handleFocus = () => setSelectedInput(true);
  const handleBlur = () => setSelectedInput(false);

  return (
    <div
      className={`search-input-container ${
        selectedInput ? "input-container-focus" : "input-container"
      }`}
    >
      <InputGroup className={selectedInput ? "icon-focused" : ""}>
        <InputGroup.Prepend>
          <InputGroup.Text
            className={`${
              selectedInput
                ? "custom-search-span-focused"
                : "custom-search-span"
            }`}
          >
            <img src={"/icons/search.svg"} alt="search icon" />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          {...props}
          ref={inputRef}
          type="text"
          className="custom-search-input"
          placeholder={
            isMobile ? t("SearchInput.search") : t("SearchInput.searchEvents")
          }
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </InputGroup>
    </div>
  );
}
