import React, { Fragment } from "react";
import NewButton from "../../../../../components/NewButton";
import { useTranslation } from "react-i18next";

export default function ProcessingSection({ status, handleRedirect }) {
  const { t } = useTranslation();
  return (
    <Fragment>
      {status === "processing" && (
        <div className="d-flex justify-content-center align-items-center">
          <NewButton
            className="primary-contained"
            onClick={() => handleRedirect("myOrders")}
            content={
              <Fragment>{t("CheckoutStatus.buttons.myOrders")}</Fragment>
            }
          />
        </div>
      )}
    </Fragment>
  );
}
