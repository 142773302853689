import React, { Fragment } from "react";
import NewButton from "../../../../../components/NewButton";
import { useTranslation } from "react-i18next";

export default function ConcludedSection({ handleRedirect }) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="d-flex justify-content-center align-items-center">
        <NewButton
          className="primary-contained"
          onClick={() => handleRedirect("myTickets")}
          content={<Fragment>{t("CheckoutStatus.buttons.myTickets")}</Fragment>}
        />
      </div>
    </Fragment>
  );
}
