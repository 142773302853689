import PropTypes from "prop-types";
import React from "react";
import EventGridContainer from "../Events/EventGridContainer";
import "./index.css";
import { useTranslation } from "react-i18next";

Destaques.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      thumb: PropTypes.string,
      is_online: PropTypes.bool,
      mes: PropTypes.string,
      dia: PropTypes.string,
      address: PropTypes.string,
      address_name: PropTypes.string,
      category_id: PropTypes.number,
    })
  ),
  event: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      thumb: PropTypes.string,
      is_online: PropTypes.bool,
      mes: PropTypes.string,
      dia: PropTypes.string,
      address: PropTypes.string,
      address_name: PropTypes.string,
      category_id: PropTypes.number,
    })
  ),
};

function Destaques(props) {
  const { event, events } = props;
  const normalEvents = event;
  const { t } = useTranslation();

  return (
    <div id="main-events" className="container">
      <h2 style={{ fontSize: "x-large" }}>{t("Highlights.highlightEvents")}</h2>
      <div className="events-list">
        <div className="event-list-row">
          {events.length <= 0 ? (
            <EventGridContainer events={normalEvents.slice(0, 3)} />
          ) : (
            <EventGridContainer events={events} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Destaques;
