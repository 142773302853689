class KondutoService {
  static loadScript = () => {
    return new Promise((resolve) => {
      if (!process.env.REACT_APP_FRAUD_PUBLIC_KEY) {
        resolve();
      } else {
        if (document.querySelector(`script[id="kdtjs"]`)) {
          resolve();
        } else {
          const vars = document.createElement("script");
          const analytix = document.createElement("script");

          vars.type = "text/javascript";
          vars.innerHTML = `var __kdt = __kdt || [];
        __kdt.push({"public_key": "${process.env.REACT_APP_FRAUD_PUBLIC_KEY}"});
        __kdt.push({"post_on_load": false});`;

          analytix.id = "kdtjs";
          analytix.src = "https://i.k-analytix.com/k.js";
          analytix.type = "text/javascript";
          analytix.async = true;

          analytix.onload = () => {
            resolve();
          };

          document.body.appendChild(vars);
          document.body.appendChild(analytix);
        }
      }
    });
  };

  static sendEvent = (tag, event) => {
    if (!process.env.REACT_APP_FRAUD_PUBLIC_KEY) {
      return;
    }

    const period = 300;
    const limit = 20 * 1e3;

    let nTry = 0;

    const intervalID = setInterval(function () {
      var clear = limit / period <= ++nTry;
      if (window["Konduto"]) {
        window["Konduto"].sendEvent(tag, event);
        clear = true;
      }

      if (clear) {
        clearInterval(intervalID);
      }
    }, period);
  };

  static setCustomerID = (email) => {
    if (!process.env.REACT_APP_FRAUD_PUBLIC_KEY) {
      return;
    }

    const period = 300;
    const limit = 20 * 1e3;

    let nTry = 0;

    const intervalID = setInterval(function () {
      var clear = limit / period <= ++nTry;
      if (window["Konduto"]) {
        window["Konduto"].setCustomerID(email);
        clear = true;
      }

      if (clear) {
        clearInterval(intervalID);
      }
    }, period);
  };
}

export default KondutoService;
