import React from "react";
import { ReactComponent as TicketExhibit } from "../../assets/svg/ticket-exhibit.svg";
import "./styles.css";

export default function TicketExhibitComponent({ ticketName, eventName }) {
  return (
    <div className="d-flex flex-row ticket-exhibit py-3 w-100">
      <div className="py-1 pr-2">
        <TicketExhibit width={24} height={24} />
      </div>
      <div className="d-flex flex-column ticket-name-event-container">
        <p className="ticket-name">{ticketName}</p>
        <p>{eventName}</p>
      </div>
    </div>
  );
}
