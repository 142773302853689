import { isInZigApp } from "../services/utils";

export const useZendesk = () => {
  const initialLanguage = localStorage.getItem("i18nextLng");
  const isWhitelabel = !!Number(process.env.REACT_APP_IS_WHITELABEL);

  const show = () => {
    if (!isInZigApp() && !isWhitelabel) {
      setTimeout(() => {
        if (window.zE) {
          window.zE("messenger", "show");
          window.zE("messenger:set", "zIndex", 1);
          window.zE("messenger:set", "locale", initialLanguage || "pt-BR");
        }
      }, 1500);
    }
  };

  const hide = () => {
    if (window.zE) {
      window.zE("messenger", "hide");
    }
  };

  const changeLocale = (locale) => {
    if (window.zE) {
      window.zE("messenger:set", "locale", locale || "pt-BR");
    }
  };

  return {
    show,
    hide,
    changeLocale,
  };
};
