import React, { useEffect, useState } from "react";
import NewApi from "../../services/new-api";

import "./styles.css";
import NominalTransferFirstScreen from "./FirstScreen";
import NominalTransferInstructionsModal from "../../components/NominalTransferInstructionsModal";
import { SomethingUnplannedHappenedModal } from "../../components/SomethingUnplannedHappened";

import { Container } from "react-bootstrap";
import Loader from "../../components/Loader";
import NominalTransferSecondScreen from "./SecondScreen";
import { useTranslation } from "react-i18next";

const newApi = new NewApi();

export default function NominalTransfer() {
  const { t } = useTranslation();
  const [data, setData] = useState({});

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [screen, setScreen] = useState(1);
  const [show, setShow] = useState(false);
  const [loading, setIsLoading] = useState(true);

  const [ticket, setTicket] = useState({
    id: "",
    eventName: "",
    ticketName: "",
    maxTransferDate: "",
  });

  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("ticket");

  useEffect(() => {
    newApi
      .get(`tickets/ownership/${id}`)
      .then(({ data }) => {
        setTicket({
          ticketId: data.id,
          eventName: data.event.name,
          ticketName: data.ticket_name,
          maxTransferDate: data.event.event_transfer_settings.blocking_date,
        });

        setShow(true);
        setIsLoading(false);
      })
      .catch(() => {
        setShowErrorModal(true);
        setIsLoading(false);
      });
  }, [id]);

  const handleClose = () => setShow(false);

  return (
    <Container className="nominal-transfer px-4 flex-column d-flex col-md-5">
      {loading && <Loader title={t("CommonExpressions.loading")} />}

      {screen === 1 && (
        <NominalTransferFirstScreen
          {...ticket}
          email={email}
          handleData={setData}
          onSetEmail={setEmail}
          confirmEmail={confirmEmail}
          handleScreenChange={setScreen}
          onSetConfirmEmail={setConfirmEmail}
        />
      )}
      {screen === 2 && (
        <NominalTransferSecondScreen
          {...ticket}
          data={data}
          handleScreenChange={setScreen}
        />
      )}

      <NominalTransferInstructionsModal
        show={show}
        onClose={handleClose}
        maxTransferDate={ticket.maxTransferDate}
      />

      <SomethingUnplannedHappenedModal
        show={showErrorModal}
        onClose={setShowErrorModal}
        to={"/meus-ingressos"}
      />
    </Container>
  );
}
