import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Accordion, Card, Modal, Spinner } from "react-bootstrap";
import swal from "sweetalert2";
import sendToLogger from "../../Helpers/errorLogger";
import MaskHelper from "../../Helpers/mask";
import userLogo from "../../assets/img/userLogo.png";
import { useAuth } from "../../hooks/useAuth";
import NewApi from "../../services/new-api";
import "./index.css";
import { pushGAEvent } from "../../Helpers/tagManager";
import useWindowSize from "../../hooks/useWindowSize";
import MoengageService from "../../services/moengage";
import { useTranslation } from "react-i18next";

const api = new NewApi();

export default function Login({
  show,
  onClose,
  onClickSignUp,
  onLoginCallback,
}) {
  const { isLoggingIn, login, user } = useAuth();
  const isMobile = useWindowSize();
  const { t } = useTranslation();
  const nomeEmpresa = process.env.REACT_APP_NAME_EMPRESA;
  const [invalidLogin, setInvalidLogin] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState(false);
  const [resetMail, setResetEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    // prevents screen reload
    e.preventDefault();

    pushGAEvent("clk_submit_signin_modal");

    try {
      await login({ username, password });

      if (onLoginCallback) {
        onLoginCallback();
      }

      closeModal(true);
    } catch (error) {
      if (!error.message.includes("400")) {
        sendToLogger(error);
      }
      setInvalidLogin(false);
    }
  };

  const handleSignUp = async (e) => {
    pushGAEvent("clk_submit_signup_modal");
    if (user) {
      await MoengageService.createEvent({
        type: "event",
        customer_id: user.id,
        actions: [
          {
            action: "clk_submit_signup_modal",
            attributes: {
              ...user,
            },
            platform: "web",
          },
        ],
      });
    }
    onClickSignUp(e);
  };

  const sendEmailReset = (e) => {
    e.preventDefault();
    setLoading(true);

    api
      .post("passwords", { email: resetMail })
      .then(async () => {
        pushGAEvent("forgot_pass");

        if (user) {
          await MoengageService.createEvent({
            type: "event",
            customer_id: user.id,
            actions: [
              {
                action: "Forgot password",
                attributes: {
                  user: user,
                },
                platform: "web",
              },
            ],
          });
        }

        successMessage(t("Login.resetPasswordEmailSent"));
      })
      .catch((error) => {
        errorMessage(t("Login.emailNotFound"));
        if (!error.message.includes("404")) {
          sendToLogger(error);
        }
      })
      .finally(() => {
        setResetEmail("");
        setLoading(false);
      });
  };

  const successMessage = (message) => {
    swal.fire({
      type: "success",
      icon: "success",
      title: t("CommonExpressions.success"),
      text: message,
      footer: nomeEmpresa,
    });
  };

  const errorMessage = (message) => {
    swal.fire({
      icon: "error",
      title: t("FaleForm.oops"),
      text: message,
      footer: nomeEmpresa,
    });
  };

  const closeModal = (isSucceded = false) => {
    if (onClose) {
      onClose(isSucceded);
      setInvalidLogin(true);
    }
  };

  const handleUsername = (event) => {
    let data = event.target.value || "";

    // Probably is a document field!
    if (
      MaskHelper.numberMask(data).length ===
      MaskHelper.alphanumericMask(data).length
    ) {
      data = MaskHelper.cpfMask(data);
    }

    setUsername(data);
  };

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      <Modal
        show={show}
        onHide={closeModal}
        scrollable={isMobile}
        id="login-modal"
      >
        <Modal.Header className="new-modal-header">
          <img alt="" src={userLogo} />
          <Modal.Title className="new-modal-title">
            {t("Login.login")}
          </Modal.Title>
          <p className="modal-p">{t("Login.loginOrSignup")}</p>
        </Modal.Header>
        {onClose && (
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="close-icon-login"
            onClick={() => closeModal()}
          />
        )}
        <Modal.Body className="new-modal-body">
          {loading ? (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Spinner animation="border" variant="primary" />
              <h5>{t("Login.processing")}</h5>
            </div>
          ) : (
            <>
              <div
                className="alert alert-danger text-center"
                hidden={invalidLogin}
              >
                <div>{t("Login.invalidCredentials")}</div>
              </div>
              <form>
                <div className="form-group">
                  <label htmlFor="username">{t("Login.emailCPF")}</label>
                  <input
                    className="form-control"
                    id="username"
                    value={username}
                    onChange={handleUsername}
                    placeholder={t("Login.enterYourEmailOrCpf")}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">{t("Login.password")}</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={t("Login.typePassword")}
                    required
                  />
                </div>
                <button
                  disabled={!username.length || !password.length || isLoggingIn}
                  variant="primary"
                  className="mb-3 btn-logar"
                  onClick={handleLogin}
                >
                  {isLoggingIn
                    ? t("Login.loggingIn")
                    : t("LoggedOutModal.login")}
                </button>
              </form>
              <div className="text-center ">
                <Accordion>
                  <div className="forgot-collapse">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      className="forgot-title"
                    >
                      {t("Login.forgotPassword")}{" "}
                      <span>{t("Login.clickHere")}</span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <div className="row">
                          <p className="text-center info-mail">
                            {t("Login.resetPasswordIntro")}
                          </p>
                          <div className="col-md-8 col-lg-8 col-xl-8">
                            <input
                              type="email"
                              placeholder={t("Checkout.email")}
                              className="form form-control"
                              onChange={(e) => setResetEmail(e.target.value)}
                            />
                          </div>
                          <div className="col-md-4 col-lg-4 col-xl-4 ">
                            <button
                              className="btn btn-primary btn-send"
                              onClick={sendEmailReset}
                            >
                              <i className="fa fa-send"></i>
                              {t("Login.send")}
                            </button>
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </div>
                </Accordion>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="new-modal-footer">
          <div className="w-100">
            <hr></hr>
            <div className="text-center account-question">
              <span>{t("Login.dontHaveAccount")}</span>
            </div>
            <button onClick={handleSignUp} className="login-register-bnt">
              {t("Login.signup")}
            </button>

            <p className="text-center p-register">
              {t("Login.acceptance")} <br />
              <a href="/termos" target="_blank">
                {t("Footer.termsOfUse")}
              </a>{" "}
              {t("CommonExpressions.of")} {nomeEmpresa}
            </p>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
