import React from "react";
import "./index.css";
import { useTranslation } from "react-i18next";

export default function PreviewSeatChart({ chartThumbnail, callBackSeat }) {
  const { t } = useTranslation();
  return (
    <div className="preview-container">
      <div className="container preview-content" onClick={callBackSeat}>
        <div className="preview-content-header">
          <h2 className="preview-content-header-text">
            {t("PreviewSeatChart.viewYourSeat")}
          </h2>
        </div>
        <div className="preview-content-container-img">
          <img
            className="preview-content-img"
            src={chartThumbnail}
            alt="chart-thumbnail"
          />
          <div className="preview-content-container-button">
            <button className="preview-content-button">
              {t("PreviewSeatChart.clickToAccessMap")}
            </button>
          </div>
        </div>
        <div className="preview-content-container-footer">
          <h5 className="preview-content-container-footer-text">
            {t("PreviewSeatChart.chooseSeat")}
          </h5>
        </div>
      </div>
    </div>
  );
}
