import { atom } from "recoil";

export const authStore = atom({
  key: "authStore",
  default: {
    user: JSON.parse(localStorage.getItem("user")) || null,
    isLoggingIn: false,
    isInitialized: false,
  },
});
