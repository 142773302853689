/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import {
  AddressElement,
  Elements,
  ElementsConsumer,
  PaymentElement,
} from "@stripe/react-stripe-js";

export const StripeView = ({
  stripe,
  intentInfo,
  onStripeElements,
  onStripeConsumer,
  onInitialingStripe,
}) => {
  return (
    <Elements
      stripe={stripe}
      onReady={() => {
        onInitialingStripe(false);
      }}
      options={{
        appearance: {
          variables: {
            colorText: "#12263f",
            fontSizeBase: "0.9rem",
          },
        },
        clientSecret: intentInfo.session,
      }}
    >
      <ElementsConsumer>
        {({ stripe, elements }) => {
          useEffect(() => {
            onStripeElements(elements);
            onStripeConsumer(stripe);
          }, [stripe, elements]);

          return (
            <>
              <AddressElement options={{ mode: "billing" }} />
              <div style={{ marginTop: 10 }}>
                <PaymentElement />
              </div>
            </>
          );
        }}
      </ElementsConsumer>
    </Elements>
  );
};
