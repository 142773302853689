import React, { Fragment } from "react";
import Picpay from "../../assets/svg/picpay-1 1.svg";
import QRCode from "react-qr-code";
import { Image } from "react-bootstrap";

export default function QRCodeSection({ code, isPicpay }) {
  return (
    <Fragment>
      {code && (
        <Fragment>
          <div>
            <div className="qrcode-container">
              <QRCode value={code} level="H" size="230" />
            </div>
            {isPicpay && (
              <div className="d-flex justify-content-center mt-2">
                <Image src={Picpay} alt="Picpay Logo" />
              </div>
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
