import { useRecoilState } from "recoil";
import axios from "axios";
import Swal from "sweetalert2";
import { userLocation } from "../atoms/location";
import location_pin_blue from "../assets/svg/location_pin_blue.svg";
import { useSearch } from "./useSearch";
import { IP_ERROR_TIMEOUT } from "../services/constants";
import {
  cleanUrl,
  handleSelectedStateStorage,
} from "../components/LocationModal";
import { useTranslation } from "react-i18next";

export const useLocation = () => {
  const { updateSearch } = useSearch();
  const [location, setLocation] = useRecoilState(userLocation);
  const { t } = useTranslation();

  const handleIPLocation = async (requestedByUser = false) => {
    if (!process.env.REACT_APP_IP_TRACKER_URL) {
      return;
    }

    try {
      const response = await axios.get(process.env.REACT_APP_IP_TRACKER_URL, {
        timeout: IP_ERROR_TIMEOUT,
      });

      if (response.status === 200) {
        setLocation((prevLocation) => ({
          ...prevLocation,
          userData: response.data,
        }));
        localStorage.setItem("userData", JSON.stringify(response.data));
      } else {
        handleIPLocationError(requestedByUser);
      }
    } catch (error) {
      handleIPLocationError(requestedByUser);
    }
  };

  const handleIPLocationError = (requestedByUser = false) => {
    const title = location.isAcceptedLocation
      ? t("UseLocation.updateLocation")
      : t("UseLocation.seeNearbyEvents");
    const text = location.isAcceptedLocation
      ? t("UseLocation.updateLocationDescription")
      : t("UseLocation.acceptLocationPermission");
    const confirmButtonText = location.isAcceptedLocation
      ? t("UseLocation.update")
      : t("UseLocation.allow");

    const shouldShowPopup =
      window.location.pathname === "/" ||
      window.location.pathname === "/eventos";

    if (!shouldShowPopup && !requestedByUser) {
      return;
    }

    Swal.fire({
      imageUrl: location_pin_blue,
      imageWidth: 88,
      imageHeight: 88,
      title,
      text,
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: t("UseLocation.notNow"),
      confirmButtonText,
      customClass: {
        image: "no-border",
        title: "swall-title",
        content: "swall-description",
        confirmButton: "btn-swall-confirm",
        cancelButton: "btn-swall-deny",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        handleBrowserGeolocation();
      }
      if (result.dismiss === Swal.DismissReason.cancel) {
        document.cookie = `locationPermission=denied`;
      }
    });
  };

  const handleBrowserGeolocation = async () => {
    setLocation((prevLocation) => ({
      ...prevLocation,
      loadingCoordinates: true,
    }));

    try {
      if ("geolocation" in navigator) {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const { latitude, longitude } = position.coords;

        setLocation((prevLocation) => ({
          ...prevLocation,
          isAcceptedLocation: true,
          userData: { lat: latitude, lon: longitude },
        }));
        localStorage.setItem(
          "userData",
          JSON.stringify({ lat: latitude, lon: longitude })
        );
        document.cookie = `locationPermission=granted; max-age=5184000; path=/;`;
      } else {
        throw new Error("GEOLOCATION_NOT_FOUND");
      }
    } catch (error) {
      if (error.message === "GEOLOCATION_CALLBACK_ERROR") {
        Swal.fire({
          icon: "error",
          title: t("FaleForm.oops"),
          html: t("UseLocation.getLocationError"),
        });
      } else {
        Swal.fire({
          icon: "error",
          title: t("FaleForm.oops"),
          html: t("UseLocation.browserLocationError"),
        });
      }
    } finally {
      setLocation((prevLocation) => ({
        ...prevLocation,
        loadingCoordinates: false,
      }));
      updateSearch("state", null);
      handleSelectedStateStorage();
      cleanUrl();
    }
  };

  return {
    location,
    handleIPLocation,
    handleIPLocationError,
  };
};
