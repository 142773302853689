import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import axios from "axios";
import MaskHelper from "../../Helpers/mask";
import { STATE_NAMES } from "../../services/constants";

export const BillingAddressView = ({
  watch,
  errors,
  register,
  translate,
  onSetValue,
  clearErrors,
  onFetchingZipCode,
  onZipCodeFetched,
  fetchingZipCode,
  zipCodeFetched,
}) => {
  useEffect(() => {
    const value = watch("payment.zip_code");

    if (MaskHelper.numberMask(value)?.length >= 8) {
      onFetchingZipCode(true);

      axios
        .get(`https://viacep.com.br/ws/${value}/json/`, { timeout: 5000 })
        .then(({ data }) => {
          if (!data.error) {
            onSetValue("payment.city", data.localidade);
            onSetValue("payment.state", data.uf);
            onSetValue("payment.street", data.logradouro);
            onSetValue("payment.neighborhood", data.bairro);
          }
        })
        .catch(() => {})
        .finally(() => {
          onZipCodeFetched(true);
          onFetchingZipCode(false);

          clearErrors("payment.zip_code");
          clearErrors("payment.street");
          clearErrors("payment.state");
          clearErrors("payment.neighborhood");
          clearErrors("payment.number");
          clearErrors("payment.city");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("payment.zip_code")]);

  return (
    <div>
      <Row>
        <Col xs={7} md={4} className="mb-2">
          <Form.Group>
            <Form.Label htmlFor="zip_code">
              <span className="text-danger">*</span>
              {translate("Checkout.zipcode")}
            </Form.Label>
            <Form.Control
              id="zip_code"
              type="text"
              className={errors.payment?.zip_code && "is-invalid"}
              {...register("payment.zip_code")}
              onChange={(e) =>
                onSetValue(
                  "payment.zip_code",
                  MaskHelper.zipcodeMask(e.target.value)
                )
              }
            />
            {errors.payment?.zip_code && (
              <Form.Text className="error-message">
                {errors.payment?.zip_code?.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
        {fetchingZipCode ? (
          <Col className="d-flex align-items-end">
            <span className="pb-4">
              {translate("Checkout.searchingZipcode")}
            </span>
          </Col>
        ) : (
          <Col xs={5} className="postal-code mb-2">
            <a
              rel="noopener noreferrer"
              href="https://buscacepinter.correios.com.br/app/endereco/index.php"
              target="_blank"
              onClick={() => onZipCodeFetched(true)}
            >
              {translate("Checkout.unknownZipcode")}
            </a>
          </Col>
        )}
      </Row>

      <Row className={zipCodeFetched ? "d-flex" : "d-none"}>
        <Col md={7} className="mb-2">
          <Form.Group>
            <Form.Label htmlFor="address">
              <span className="text-danger">*</span>
              {translate("Checkout.address")}
            </Form.Label>
            <Form.Control
              id="street"
              type="text"
              className={errors.payment?.street && "is-invalid"}
              {...register("payment.street")}
              onChange={({ target }) =>
                onSetValue("payment.street", target.value)
              }
            />
            {errors.payment?.street && (
              <Form.Text className="error-message">
                {errors.payment?.street?.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
        <Col md={5} className="mb-2">
          <Form.Group>
            <Form.Label>
              <span className="text-danger">*</span>
              {translate("Checkout.number")}
            </Form.Label>
            <Form.Control
              id="number"
              type="text"
              className={errors.payment?.number && "is-invalid"}
              {...register("payment.number")}
              onChange={({ target }) =>
                onSetValue("payment.number", target.value.replace(/\D/g, ""))
              }
            />
            {errors.payment?.number && (
              <Form.Text className="error-message">
                {errors.payment?.number?.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
        <Col md={4} className="mb-2">
          <Form.Group>
            <Form.Label htmlFor="neighborhood">
              <span className="text-danger">*</span>
              {translate("Checkout.neighborhood")}
            </Form.Label>
            <Form.Control
              id="neighborhood"
              type="text"
              className={errors.payment?.neighborhood && "is-invalid"}
              {...register("payment.neighborhood")}
              onChange={({ target }) =>
                onSetValue("payment.neighborhood", target.value)
              }
            />
            {errors.payment?.neighborhood && (
              <Form.Text className="error-message">
                {errors.payment?.neighborhood?.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
        <Col md={4} className="mb-2">
          <Form.Group>
            <Form.Label htmlFor="city">
              <span className="text-danger">*</span>
              {translate("Checkout.city")}
            </Form.Label>
            <Form.Control
              id="city"
              name="city"
              type="text"
              className={errors.payment?.city && "is-invalid"}
              {...register("payment.city")}
              onChange={({ target }) =>
                onSetValue("payment.city", target.value)
              }
            />
            {errors.payment?.city && (
              <Form.Text className="error-message">
                {errors.payment?.city?.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
        <Col md={4} className="mb-2">
          <Form.Group htmlFor="state">
            <Form.Label htmlFor="state">
              <span className="text-danger">*</span>
              {translate("Checkout.state")}
            </Form.Label>
            <Form.Control
              id="state"
              as="select"
              className={errors.payment?.state && "is-invalid"}
              {...register("payment.state")}
              onChange={({ target }) =>
                onSetValue("payment.state", target.value)
              }
            >
              <option value="">{translate("Checkout.selectState")}</option>
              {Object.keys(STATE_NAMES).map((abbr, idx) => (
                <option id={abbr} key={idx} value={abbr}>
                  {STATE_NAMES[abbr]}
                </option>
              ))}
            </Form.Control>
            {errors.payment?.state && (
              <Form.Text className="error-message">
                {errors.payment?.state?.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};
