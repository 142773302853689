import React, { createContext, useContext } from "react";

const GatewayContext = createContext();

export const useGateway = () => useContext(GatewayContext);

export const GatewayProvider = ({ yunoInstance, stripeInstance, children }) => (
  <GatewayContext.Provider
    value={{ yuno: yunoInstance, stripe: stripeInstance }}
  >
    {children}
  </GatewayContext.Provider>
);
