import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as NominalTransferInstructionIcon } from "../../assets/svg/nominal_transfer_instruction.svg";
import { ReactComponent as NominalTransferCalendar } from "../../assets/svg/nominal-transfer-calendar.svg";
import { ReactComponent as NominalTransferMail } from "../../assets/svg/nominal-transfer-mail.svg";
import { ReactComponent as NominalTransferCheck } from "../../assets/svg/nominal-transfer-check.svg";
import { Button, Modal } from "react-bootstrap";

import "./styles.css";
export default function NominalTransferInstructionsModal({
  show,
  onClose,
  maxTransferDate,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      size="lg"
      onHide={onClose}
      backdrop={"static"}
      keyboard={false}
      centered
    >
      <Modal.Body className="d-flex align-items-center justify-content-center flex-column text-center nominal-transfer-modal">
        <NominalTransferInstructionIcon width={100} height={100} className="" />

        <h3 className="nominal-transfer-instruction-title">
          {t("NominalTransfer.practicalNominalTransfer")}
        </h3>

        <div className="transfer-instructions-item align-items-center w-100 d-flex flex-row text-left">
          <NominalTransferCalendar width={32} height={32} />
          <div>
            <p>
              {t("NominalTransfer.canBeMadeUntil")}{" "}
              <span className="font-weight-bold">
                {maxTransferDate
                  ? maxTransferDate
                  : t("NominalTransfer.thirtyMinutesBeforeEvent")}
              </span>
            </p>
          </div>
        </div>
        <div className="transfer-instructions-item align-items-center w-100 d-flex flex-row text-left">
          <NominalTransferMail width={32} height={32} />
          <div>
            <p>{t("NominalTransfer.informReceiverEmail")}</p>
          </div>
        </div>
        <div className="transfer-instructions-item align-items-center w-100 d-flex flex-row text-left">
          <NominalTransferCheck width={32} height={32} />
          <div>
            <p>{t("NominalTransfer.willBeSendToEmail")}</p>
          </div>
        </div>

        <Button onClick={onClose} className="w-100" id="confirm">
          {t("NominalTransfer.confirm")}
        </Button>
      </Modal.Body>
    </Modal>
  );
}
