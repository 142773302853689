import ReactGA4 from "react-ga4";
import TagManager from "react-gtm-module";

export function pushDataLayerAndEvent(dataLayer) {
  if (process.env.REACT_APP_GTM_CODE) {
    TagManager.dataLayer({
      gtmId: process.env.REACT_APP_GTM_CODE,
      dataLayer,
    });
  }
}

export function pushGAEvent(event, options) {
  if (ReactGA4.isInitialized) {
    ReactGA4.event(event, options);
  }
}
