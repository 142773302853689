import React, { Fragment } from "react";
import { ReactComponent as PixIcon } from "../../../../../assets/svg/new_pix.svg";
import { ReactComponent as Copy } from "../../../../../assets/svg/Copy.svg";
import { ReactComponent as Checked } from "../../../../../assets/svg/new_check.svg";
import NewInput from "../../../../../components/NewInput";
import NewButton from "../../../../../components/NewButton";
import { useTranslation } from "react-i18next";

function PixSection({ pix, handleCopy, isCopy }) {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="col-12 row d-flex justify-content-center align-items-center checkout-informations">
        <div className="col-md-7 col-12 pr-0 checkout-input">
          <NewInput
            icon={<PixIcon width={20} height={20} />}
            className="icon-new-input"
            type="text"
            value={pix}
          />
        </div>
        <div className="col-md-5 col-12 pl-1 checkout-copy-button">
          <NewButton
            className="primary-contained"
            onClick={handleCopy}
            content={
              <Fragment>
                {isCopy ? (
                  <Fragment>
                    <Checked /> {t("CheckoutStatus.buttons.pixCopiedCode")}
                  </Fragment>
                ) : (
                  <Fragment>
                    <Copy /> {t("CheckoutStatus.buttons.pixCopyCode")}
                  </Fragment>
                )}
              </Fragment>
            }
          />
        </div>
      </div>
    </Fragment>
  );
}

export default PixSection;
