import React, { useState } from "react";
import { useSearch } from "../../hooks/useSearch.jsx";
import { useLocation } from "../../hooks/useLocation";
import "./index.css";
import LocationModal from "../LocationModal";
import location_pin_main from "../../assets/svg/location_pin_main.svg";
import { useTranslation } from "react-i18next";
import NewApi from "../../services/new-api.js";

const newApi = new NewApi();

const LocationButton = () => {
  const { updateSearch, selectedState } = useSearch();
  const { location } = useLocation();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = window.matchMedia("(max-width: 991px)");
  const buttonText = location.loadingCoordinates
    ? t("LocationModal.updating")
    : location.isAcceptedLocation
    ? selectedState || t("LocationModal.useMyLocation")
    : selectedState || t("LocationModal.allStates");

  return (
    <>
      <div className="location-container">
        <button
          onClick={() => setIsModalOpen(true)}
          className="location-button"
        >
          <img src={location_pin_main} alt="location-button"></img>{" "}
          {!isMobile.matches && (
            <>
              <span>{buttonText}</span>
              <i className="fas fa-chevron-down"></i>
            </>
          )}
        </button>
      </div>
      <LocationModal
        show={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onLocationSelected={(state) => updateSearch("state", state)}
        api={newApi}
      />
    </>
  );
};

export default LocationButton;
