import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

export default function TipSection({ status, type }) {
  const { t } = useTranslation();

  if (type === "boleto") {
    return null;
  }

  const isPendingOrConcluded = status === "pending" || status === "concluded";

  return (
    <Fragment>
      {isPendingOrConcluded && (
        <Fragment>
          {status === "concluded" && <hr id="divider-checkout" />}
          <p className="checkout-tip">{t("CheckoutStatus.common.tipTitle")}</p>
          <p className="checkout-tip-text">
            {t("CheckoutStatus.common.tipContent")}
          </p>
        </Fragment>
      )}
    </Fragment>
  );
}
