import React from "react";
import { Element } from "react-scroll";
import { Card } from "react-bootstrap";

import { YunoView } from "./YunoView";
import { PagseguroView } from "./PagseguroView";
import { StripeView } from "./StripeView";

export const CardPaymentFormView = ({
  user,
  watch,
  isZig,
  errors,
  stripe,
  cardCvv,
  isStripe,
  cardType,
  register,
  translate,
  orderData,
  onSetValue,
  intentInfo,
  cardMethods,
  clearErrors,
  walletMethods,
  onZipCodeFetched,
  onStripeElements,
  onStripeConsumer,
  stripeIsInitialized,
  onInitialingStripe,
}) => {
  return (
    <Element name="cardPaymentInfos" className="card">
      <Card.Header>
        <div className="d-inline-block" id="billing-address-info">
          {translate("Checkout.insertCardData")}
        </div>
      </Card.Header>
      <Card.Body>
        <YunoView
          isZig={isZig}
          watch={watch}
          orderData={orderData}
          walletMethods={walletMethods}
        />

        {((!isZig() && !isStripe()) || orderData?.value === 0) && (
          <PagseguroView
            user={user}
            watch={watch}
            errors={errors}
            cardCvv={cardCvv}
            register={register}
            cardType={cardType}
            translate={translate}
            onSetValue={onSetValue}
            clearErrors={clearErrors}
            cardMethods={cardMethods}
            onZipCodeFetched={onZipCodeFetched}
          />
        )}

        {isStripe() &&
          stripe &&
          intentInfo?.session &&
          stripeIsInitialized &&
          orderData?.value > 0 && (
            <StripeView
              stripe={stripe}
              intentInfo={intentInfo}
              onStripeElements={onStripeElements}
              onStripeConsumer={onStripeConsumer}
              onInitialingStripe={onInitialingStripe}
            />
          )}
      </Card.Body>
    </Element>
  );
};
