import React from "react";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const index = ({ title, text, style }) => (
  <div id="loading" style={style}>
    <div id="loading-symbol">
      <div id="loading-border"></div>
      <FontAwesomeIcon icon={faCircleNotch} id="loading-icon" />
    </div>
    <p>{title}</p>
    <p>{text}</p>
  </div>
);

export default index;
