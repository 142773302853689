import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import MaskHelper from "../../../Helpers/mask";
import { list } from "radash";

export const PagseguroView = ({
  watch,
  errors,
  cardCvv,
  register,
  cardType,
  translate,
  onSetValue,
  cardMethods,
}) => {
  return (
    <>
      <div
        className={
          cardMethods.includes(watch("payment.payment_type"))
            ? "d-block"
            : "d-none"
        }
      >
        <Row>
          <Col sm={12} className="mb-2">
            <Form.Group>
              <Form.Label htmlFor="card_holder">
                <span className="text-danger">*</span>
                {translate("Checkout.printedNameOnCard")}
              </Form.Label>
              <Form.Control
                id="card_holder"
                type="text"
                className={errors.payment?.card_holder && "is-invalid"}
                {...register("payment.card_holder")}
                onChange={(e) =>
                  onSetValue(
                    "payment.card_holder",
                    e.target.value.replace(/[^a-zA-Z ]/gi, "")
                  )
                }
              />
              {errors.payment?.card_holder && (
                <Form.Text className="error-message">
                  {errors.payment?.card_holder?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>

          <Col md={6} className="mb-2">
            <Form.Group>
              <Form.Label htmlFor="card_number">
                <span className="text-danger">*</span>
                {translate("Checkout.cardNumber")}
              </Form.Label>
              <Form.Control
                id="card_number"
                type="text"
                className={errors.payment?.card_number && "is-invalid"}
                placeholder="0000 0000 0000 0000"
                {...register("payment.card_number")}
                onChange={(e) =>
                  onSetValue(
                    "payment.card_number",
                    MaskHelper.commonCardMask(e.target.value)
                  )
                }
              />

              {cardType && (
                <div className="card-brand">
                  <img src={`/${cardType}.svg`} alt={cardType} />
                </div>
              )}

              {errors.payment?.card_number && (
                <Form.Text className="error-message">
                  {errors.payment?.card_number?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col xs={6} md={3} className="mb-2">
            <Form.Group>
              <Form.Label htmlFor="card_expiration">
                <span className="text-danger">*</span>
                {translate("Checkout.expirationDate")}
              </Form.Label>
              <Form.Control
                id="card_expiration"
                type="text"
                className={errors.payment?.card_expiration && "is-invalid"}
                placeholder="MM/AA"
                {...register("payment.card_expiration")}
                onChange={(e) =>
                  onSetValue(
                    "payment.card_expiration",
                    MaskHelper.cardExpirationMask(e.target.value)
                  )
                }
              />
              {errors.payment?.card_expiration && (
                <Form.Text className="error-message">
                  {errors.payment?.card_expiration?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col xs={6} md={3} className="mb-2">
            <Form.Group>
              <Form.Label htmlFor="card_cvv">
                <span className="text-danger">*</span>
                {translate("Checkout.securityCode")}
              </Form.Label>
              <Form.Control
                id="card_cvv"
                name="card_cvv"
                type="text"
                className={errors.payment?.card_cvv && "is-invalid"}
                placeholder={list(0, cardCvv - 1, "0").join("")}
                {...register("payment.card_cvv")}
                onChange={(e) =>
                  onSetValue(
                    "payment.card_cvv",
                    MaskHelper.numberMask(e.target.value).replace(
                      new RegExp("(\\d{" + cardCvv + "})\\d+?$", "gi"),
                      "$1"
                    )
                  )
                }
              />
              {errors.payment?.card_cvv && (
                <Form.Text className="error-message">
                  {errors.payment?.card_cvv?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
      </div>
    </>
  );
};
