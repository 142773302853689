import React, { Fragment } from "react";
import NewButton from "../../../../../components/NewButton";
import { ReactComponent as BarCode } from "../../../../../assets/svg/Bar Code.svg";

export default function BoletoSection({ handleRedirect }) {
  return (
    <Fragment>
      <div className="d-flex justify-content-center align-items-center">
        <NewButton
          className="primary-contained"
          onClick={() => handleRedirect("boleto")}
          content={
            <Fragment>
              <Fragment>
                <BarCode />
                Ver boleto
              </Fragment>
            </Fragment>
          }
        />
      </div>
    </Fragment>
  );
}
