/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import sendToLogger from "../../Helpers/errorLogger";
import Loader from "../../components/Loader";
import PaginatedEvents from "../../components/PaginatedEvents";
import { useSearch } from "../../hooks/useSearch";
import NewApi from "../../services/new-api";
import "./index.css";
import { pushDataLayerAndEvent, pushGAEvent } from "../../Helpers/tagManager";
import { useLocation } from "../../hooks/useLocation";
import { transformStateName } from "../../services/constants";
import { useAuth } from "../../hooks/useAuth";
import MoengageService from "../../services/moengage";
import { useTranslation } from "react-i18next";
import { useZendesk } from "../../hooks/useZendesk";

const api = new NewApi();

const SearchResults = () => {
  const { user } = useAuth();
  const { typedText, selectedState } = useSearch();
  const { location } = useLocation();
  const { t } = useTranslation();
  const { hide } = useZendesk();
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [searchedEvents, setSearchedEvents] = useState({
    items: [],
    page: 1,
    total: 0,
    inLastPage: false,
  });
  const [nextEvents, setNextEvents] = useState({
    items: [],
    page: 1,
    total: 0,
    inLastPage: false,
  });

  const handleEventsFromApi = async (currentPage = 1) => {
    try {
      setLoading(currentPage === 1);
      setLoadingMore(true);
      const state = transformStateName(selectedState);

      const {
        data: { data, meta },
      } = await api.get("events", {
        params: {
          by_name: typedText,
          order_by_state: state,
          latitude: location.userData?.lat,
          longitude: location.userData?.lon,
          within_past_year: true,
          active_or_expired: true,
          page: currentPage,
          per_page: 12,
        },
      });

      if (currentPage === 1) {
        const term = typedText || selectedState || "-";

        pushDataLayerAndEvent({
          search_term: term,
          search_total_results: meta.total,
        });
        pushGAEvent("search", {
          search_term: term,
        });

        if (user) {
          await MoengageService.createEvent({
            type: "event",
            customer_id: user.id,
            actions: [
              {
                action: "Search",
                attributes: {
                  search_term: term,
                },
                platform: "web",
              },
            ],
          });
        }
      }

      setSearchedEvents((state) => ({
        page: currentPage,
        total: meta.total,
        items: currentPage === 1 ? data : [...state.items, ...data],
        inLastPage: meta.current_page === meta.last_page,
      }));

      if (currentPage === 1 && meta.total === 0) {
        await handleNextEventsFromApi(1);
      }

      return data;
    } catch (error) {
      sendToLogger(error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  const handleNextEventsFromApi = async (currentPage = 1) => {
    try {
      setLoading(currentPage === 1);
      setLoadingMore(true);

      const {
        data: { data, meta },
      } = await api.get("events", {
        params: {
          latitude: location.userData?.lat,
          longitude: location.userData?.lon,
          page: currentPage,
          per_page: 8,
        },
      });

      setNextEvents((state) => ({
        page: currentPage,
        total: meta.total,
        items: currentPage === 1 ? data : [...state.items, ...data],
        inLastPage: meta.current_page === meta.last_page,
      }));

      return data;
    } catch (error) {
      sendToLogger(error);
    } finally {
      setLoading(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    setNextEvents({ items: [], total: 0, inLastPage: false });
    handleEventsFromApi(1);
  }, [typedText, selectedState, location.isAcceptedLocation]);

  useEffect(() => hide(), []);

  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <Loader
            title={t("Checkout.wait")}
            text={t("SearchResults.searchingEvents")}
          />
        </div>
      ) : (
        <div className="container-fluid">
          <h2 className={typedText ? "near-events-searched" : ""}>
            {typedText && `"${typedText}"`}
          </h2>

          {!loading && searchedEvents.total > 0 && (
            <h6 className="near-events-searched">
              {searchedEvents.total} {t("SearchResults.foundEvents")}
            </h6>
          )}

          {!loading && searchedEvents.total === 0 && typedText && (
            <div className="error-container">
              <h2 className="main-title">{t("SearchResults.noEventsFound")}</h2>
              <h5 className="description">
                {t("SearchResults.noEventsFoundDescription")}
              </h5>
            </div>
          )}

          <PaginatedEvents
            events={searchedEvents.items}
            isLoading={loadingMore}
            showLoadMore={!searchedEvents.inLastPage}
            onLoadMore={() => handleEventsFromApi(searchedEvents.page + 1)}
          />

          {!loading && searchedEvents.total === 0 && (
            <>
              <div
                id="near-events"
                className="container"
                style={{ minHeight: 0 }}
              >
                <h2
                  className="title"
                  style={{ textAlign: "center", fontSize: "x-large" }}
                >
                  {location.isAcceptedLocation
                    ? t("Index.eventsNearYou")
                    : t("Index.upcomingEvents")}
                </h2>
              </div>

              <PaginatedEvents
                events={nextEvents.items}
                isLoading={loadingMore}
                showLoadMore={!nextEvents.inLastPage}
                onLoadMore={() => handleNextEventsFromApi(nextEvents.page + 1)}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchResults;
