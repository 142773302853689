import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Row } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

import MaskHelper from "../../Helpers/mask";
import { useTranslation } from "react-i18next";

function TicketListView({
  tickets,
  maxDiscountUse,
  ticketsQuantity,
  maxTicketsPerOrder,
  onChangeQuantities,
  isLoadingDiscount,
  isCodeApplied,
}) {
  const { t } = useTranslation();
  const sectorAmount = (tickets || []).reduce((acc, obj) => {
    if (!acc[obj.eventSectorLotId]) {
      acc[obj.eventSectorLotId] = 0;
    }
    acc[obj.eventSectorLotId] += obj.qty;
    return acc;
  }, {});
  return (
    <>
      {tickets.map((item, index) => (
        <Row key={`ticket-line-${index}`} className="ticket-line" noGutters>
          <Col xs={8} sm={9} className="pr-3 ticket-price">
            <div>
              <span className="ticket-name">
                {item.name} &nbsp;
                {!!item.description && (
                  <span data-tip={item.description}>
                    <ReactTooltip html={true} place="top" effect="solid" />
                    <FontAwesomeIcon icon={faQuestionCircle} size="1x" />{" "}
                  </span>
                )}
              </span>
            </div>

            {item.discount >= 0 && item.discount !== null ? (
              <div>
                <span
                  style={{
                    textDecoration: "line-through",
                  }}
                >
                  {" "}
                  {MaskHelper.numberToLocaleCurrency(Number(item.value))}
                </span>
                <span>
                  &nbsp;{" "}
                  {MaskHelper.numberToLocaleCurrency(Number(item.discount))}
                  {item.fee > 0 && (
                    <>
                      ({t("TicketInfoView.fee")}{" "}
                      {MaskHelper.numberToLocaleCurrency(item.fee)})
                    </>
                  )}
                </span>
              </div>
            ) : (
              <div>
                <span>
                  {MaskHelper.numberToLocaleCurrency(item.value)}
                  {item.fee > 0 && (
                    <>
                      ({t("TicketInfoView.fee")}{" "}
                      {MaskHelper.numberToLocaleCurrency(item.fee)})
                    </>
                  )}
                </span>
                {!isLoadingDiscount && isCodeApplied && !item.discount && (
                  <span className="ticket-warning">
                    {t("TicketListView.couponNotApplied")}
                  </span>
                )}
              </div>
            )}
          </Col>

          <Col
            xs={4}
            sm={3}
            className="d-flex justify-content-end align-items-center"
          >
            {item.available > 0 ? (
              <div className="ticket-control">
                <button
                  disabled={item.qty === 0}
                  type="button"
                  onClick={() => onChangeQuantities(item, "plus")}
                  className="btn btn-sm btn btn-primary btn btn-primary"
                >
                  -
                </button>
                <span className="ticket-number">{item.qty}</span>
                <button
                  disabled={
                    item.qty >= item.available ||
                    item.available <= sectorAmount[item.sectorId] ||
                    item.qty >= item.maximum ||
                    (item.discount >= 0 &&
                    item.discount !== null &&
                    maxDiscountUse
                      ? ticketsQuantity >= maxDiscountUse
                      : false) ||
                    ticketsQuantity >= maxTicketsPerOrder
                  }
                  type="button"
                  onClick={() => onChangeQuantities(item, "minus")}
                  className="btn btn-sm btn btn-primary btn btn-primary"
                >
                  +
                </button>
              </div>
            ) : (
              <div className="span-esgotado">
                <span>{t("TicketListView.soldOff")}</span>
              </div>
            )}
          </Col>
        </Row>
      ))}
    </>
  );
}

export default TicketListView;
