import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import "./index.css";
import CountDown from "../CountDown";
import { useTranslation } from "react-i18next";

export default function LoggedOutModal(props) {
  const { expiresIn, timeToExpire, onHide, show, onShowCart, onLoginClicked } =
    props;
  const { t } = useTranslation();
  const handleOpenLoginModal = () => {
    onHide();
    onLoginClicked(true);
  };

  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <div>
          <h1 className="loggedOut-title">
            {t("LoggedOutModal.loggedOutAlert")}
          </h1>
        </div>
      </Modal.Header>
      <div className="pt-3 pl-2">
        <h2 className="loggedOut-subTitle">{t("LoggedOutModal.alertTitle")}</h2>
      </div>
      <div className="row pl-2">
        <div className="col-md-10 col-7">
          <h3 className="loggedOut-information">
            {t("LoggedOutModal.alertMessage")}
          </h3>
        </div>
        <div className="col-md-2  col-5 row d-flex justify-content-center align-items-center">
          <div className="pr-2 loggedOut-timer-clock">
            <FontAwesomeIcon icon={faClock} id="timer" />
          </div>
          <div>
            <h1 className="loggedOut-timer">
              <CountDown
                expiresIn={expiresIn}
                timeToExpire={timeToExpire}
                onFinishTimer={() => onShowCart(false)}
              />
            </h1>
          </div>
        </div>
      </div>
      <div className="row pt-4 d-flex justify-content-around">
        <div className="col-md-6 col-12  mb-3">
          <div className="col-12 buttons-continue-container">
            <button onClick={onHide} className="w-100 buttons-loggedOutModal">
              {t("LoggedOutModal.continue")}
            </button>
          </div>
        </div>
        <div className="col-md-6 col-12  mb-3">
          <div className="col-12 buttons-signIn-container">
            <button
              onClick={handleOpenLoginModal}
              className="w-100 buttons-loggedOutModal"
            >
              {t("LoggedOutModal.login")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
