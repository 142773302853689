import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import sendToLogger from "../../../Helpers/errorLogger";
import ErrorHandler from "../../../services/error-handler";
import NewApi from "../../../services/new-api";
import { useTranslation } from "react-i18next";

export default function PasswordForm() {
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation();

  const schema = yup.object().shape({
    password: yup.string().required(t("PasswordForm.passwordRequired")),
    newPassword: yup
      .string()
      .required(t("PasswordForm.passwordNotProvided"))
      .min(6, t("PasswordForm.passwordMinLength")),
    confirmNewPassword: yup
      .string()
      .equals([yup.ref("newPassword")], t("PasswordForm.passwordsMustMatch")),
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: { password: "", newPassword: "", confirmNewPassword: "" },
  });

  const onSubmit = async (data) => {
    setSubmitting(true);

    const formData = {
      password: data.password,
      new_password: data.newPassword,
      new_password_confirmation: data.confirmNewPassword,
    };

    new NewApi()
      .put("users/password", formData)
      .then(() => {
        reset({ password: "", newPassword: "", confirmNewPassword: "" });

        Swal.fire({
          icon: "success",
          title: t("CommonExpressions.success"),
          text: t("PasswordForm.passwordUpdatedSuccessfully"),
        });
      })
      .catch((error) => {
        sendToLogger(error);
        Swal.fire({
          icon: "error",
          title: t("CommonExpressions.ops"),
          html: ErrorHandler.handleFormErrors(error.response.data, t),
        });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
      <h4 className="mb-4">{t("PasswordForm.changePassword")}</h4>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group controlId="password">
            <Form.Label>{t("PasswordForm.currentPassword")}</Form.Label>
            <Form.Control type="password" {...register("password")} />
            {errors.password && (
              <Form.Text className="text-danger">
                {errors.password.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group controlId="newPassword">
            <Form.Label>{t("PasswordForm.newPassword")}</Form.Label>
            <Form.Control type="password" {...register("newPassword")} />
            {errors.newPassword && (
              <Form.Text className="text-danger">
                {errors.newPassword.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group controlId="confirmNewPassword">
            <Form.Label>{t("PasswordForm.confirmNewPassword")}</Form.Label>
            <Form.Control type="password" {...register("confirmNewPassword")} />
            {errors.confirmNewPassword && (
              <Form.Text className="text-danger">
                {errors.confirmNewPassword.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Button
            type="submit"
            className="btn-logar mt-3"
            disabled={submitting}
          >
            {submitting
              ? t("CommonExpressions.updating")
              : t("PasswordForm.updatePassword")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
