import { searchInputText } from "../atoms/search";

import { useRecoilState } from "recoil";

export const useSearch = () => {
  const [search, setSearch] = useRecoilState(searchInputText);

  const updateSearch = (field, text) => {
    setSearch((search) => ({
      ...search,
      [field]: text,
    }));
  };

  return {
    selectedState: search.state,
    typedText: search.search,
    updateSearch,
  };
};
