import DateHelper from "./date";
import DistanceCalculatorHelper from "./distanceCalculator";

export default class EventHelper {
  static buildPeriod = (event) => {
    const format = "ddd, DD [de] MMMM [de] YYYY [às] HH:mm";

    if (event.start_date && event.end_date) {
      return `${DateHelper.format(
        event.start_date,
        format
      )} - ${DateHelper.format(event.end_date, format)}`;
    }

    return "-";
  };

  static startDay = (event) => {
    if (event.start_date) {
      return DateHelper.format(event.start_date, "DD");
    }

    return "-";
  };

  static startMonth = (event) => {
    if (event.start_date) {
      return DateHelper.format(event.start_date, "MMM");
    }

    return "-";
  };

  static formattedDay = (event) => {
    if (event.start_date) {
      const i18nextLang = localStorage.getItem("i18nextLng");
      const format =
        i18nextLang === "en-US"
          ? "MMMM DD, YYYY [at] HH:mm"
          : "DD [de] MMMM [de] YYYY [às] HH:mm";
      return DateHelper.format(event.start_date, format);
    }

    return "-";
  };

  static sortByDistance = (events, userLatitude, userLongitude) => {
    const sortableEvent = events.map((event) => ({
      ...event,
      distance: DistanceCalculatorHelper.calculate(
        userLatitude,
        userLongitude,
        (event.event?.event_location || event.event_location).lat,
        (event.event?.event_location || event.event_location).lng
      ),
    }));

    return sortableEvent.sort((a, b) => a.distance - b.distance);
  };
}
