export default class DistanceCalculatorHelper {
  /**
   *Calculates the approximate distance (in kilometers) between two points on the Earth's surface, given their latitude and longitude coordinates.
   *
   * @param {number} userLatitude - The latitude of the user.
   * @param {number} userLongitude - The longitude of the user.
   * @param {number} eventLatitude - The latitude of the event.
   * @param {number} eventLongitude - The longitude of the event.
   * @returns {number} - The distance value between user and event location.
   */
  static calculate(userLatitude, userLongitude, eventLatitude, eventLongitude) {
    if (!userLatitude || !userLongitude) {
      return null;
    }
    const earthRadius = 6371.071;
    const radiantUserLatitude = userLatitude * (Math.PI / 180);
    const radiantEventLatitude = eventLatitude * (Math.PI / 180);
    const radianDifference = radiantUserLatitude - radiantEventLatitude;
    const radianLongitudeDifference =
      (userLongitude - eventLongitude) * (Math.PI / 180);

    const distance = Math.round(
      2 *
        earthRadius *
        Math.asin(
          Math.sqrt(
            Math.sin(radianDifference / 2) * Math.sin(radianDifference / 2) +
              Math.cos(radiantUserLatitude) *
                Math.cos(radiantEventLatitude) *
                Math.sin(radianLongitudeDifference / 2) *
                Math.sin(radianLongitudeDifference / 2)
          )
        )
    );
    return distance;
  }
}
