import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./styles.css";

NewInput.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
};

export default function NewInput({ className, icon, ...props }) {
  return (
    <Fragment>
      <div className="search-container d-flex align-items-center justify-content-start flex-row">
        {icon}
        <input className={`new-button ${className} pl-2`} {...props} />
      </div>
    </Fragment>
  );
}
