import axios from "axios";
import { eventState } from "../atoms/event";
import { useRecoilState, useSetRecoilState } from "recoil";
import { loadingState } from "../atoms/loading";

const DATA_BASE_URL = process.env.REACT_APP_INDEX_DATA_URL;
const EVENTS_JSON = process.env.REACT_APP_EVENTS_ARCHIVE_NAME;

export const useEvent = () => {
  const [events, setEvents] = useRecoilState(eventState);
  const setLoadingEvents = useSetRecoilState(loadingState);

  const populateEventsList = async () => {
    try {
      setLoadingEvents(true);
      const { data } = await axios.get(DATA_BASE_URL + EVENTS_JSON, {
        headers: { "Access-Control-Allow-Origin": "*" },
      });
      setEvents(data);
    } catch (error) {
      console.error("Error fetching events:", error);
      throw error;
    } finally {
      setLoadingEvents(false);
    }
  };

  return {
    events,
    setEvents,
    populateEventsList,
    setLoadingEvents,
  };
};
