/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import "./index.css";
import { MetaTags } from "react-meta-tags";
import { useTranslation } from "react-i18next";
import { useZendesk } from "../../../hooks/useZendesk";

export default function Termos() {
  const empresa = process.env.REACT_APP_NAME_EMPRESA || "";
  const link = process.env.REACT_APP_LINK_EMPRESA || "";
  const email = process.env.REACT_APP_MAIL_EMPRESA2 || "";
  const { t } = useTranslation();
  const { hide } = useZendesk();

  useEffect(() => {
    window.scrollTo(0, 0);
    hide();
  }, []);

  return (
    <>
      <MetaTags>
        <meta name="kdt:page" content="terms-of-use" />
      </MetaTags>

      <div className="container info-containers mt-4 mb-4">
        <div className="row terms">
          <h1>{t("UseTerms.useTermsTitle")}</h1>
          <p>
            {t("Terms.cookiesConsideration3")} {empresa}{" "}
            {t("UseTerms.useTermsMessage")} {empresa},{" "}
            {t("UseTerms.useTermsMessage2")} {empresa},{" "}
            {t("UseTerms.useTermsMessage3")} {` ${empresa} `}
            {t("UseTerms.useTermsMessage4")} {empresa},{" "}
            {t("UseTerms.useTermsMessage5")} {` ${empresa}`}{" "}
            {t("UseTerms.useTermsMessage6")}
          </p>
          <h4>
            <strong>{t("UseTerms.generalInformation")}</strong>
          </h4>

          <p>
            {t("UseTerms.generalInformationMessage")} {empresa},{" "}
            {t("UseTerms.generalInformationMessage2")} {empresa}){" "}
            {t("UseTerms.generalInformationMessage3")} {link}.
            <br />
            {t("UseTerms.generalInformationMessage4")} {` ${empresa}`}{" "}
            {t("UseTerms.generalInformationMessage5")} {empresa}{" "}
            {t("UseTerms.generalInformationMessage6")}
            <br />
            {t("UseTerms.generalInformationMessage7")} {`${empresa} `}
            {t("UseTerms.generalInformationMessage8")}
            <br />
            {t("UseTerms.generalInformationMessage9")} {empresa}{" "}
            {t("UseTerms.generalInformationMessage10")}
            <br />
            {t("UseTerms.generalInformationMessage11")} {empresa}{" "}
            {t("UseTerms.generalInformationMessage12")} {empresa}{" "}
            {t("UseTerms.generalInformationMessage13")}
            <br />
          </p>
          <h4>
            <strong>{t("UseTerms.userRegistration")}</strong>
          </h4>
          <p>
            {t("UseTerms.userRegistrationMessage")} {empresa}{" "}
            {t("UseTerms.userRegistrationMessage2")} {empresa}{" "}
            {t("UseTerms.userRegistrationMessage3")} <br />
            {t("UseTerms.userRegistrationMessage4")} {empresa}{" "}
            {t("UseTerms.userRegistrationMessage5")} {link},{" "}
            {t("UseTerms.userRegistrationMessage6")}
            <br />
            {t("UseTerms.userRegistrationMessage7")} {empresa}{" "}
            {t("UseTerms.userRegistrationMessage8")} <br />
            {t("UseTerms.userRegistrationMessage9")} {empresa}{" "}
            {t("UseTerms.userRegistrationMessage10")} <br />
            {t("UseTerms.userRegistrationMessage11")} {` ${empresa}`}. <br />
            {t("UseTerms.userRegistrationMessage12")} {empresa}{" "}
            {t("UseTerms.userRegistrationMessage13")} <br />
            {t("UseTerms.userRegistrationMessage14")} {empresa}; <br />
            {t("UseTerms.userRegistrationMessage15")} <br />
            {t("UseTerms.userRegistrationMessage16")} {empresa},{" "}
            {t("UseTerms.userRegistrationMessage17")} <br />
          </p>
          <h4>
            {" "}
            <strong>
              {t("UseTerms.platformAccess")} {empresa}{" "}
            </strong>
          </h4>

          <p>
            {t("UseTerms.platformAccessMessage")} <br />
            {t("UseTerms.platformAccessMessage2")} {empresa},{" "}
            {t("UseTerms.platformAccessMessage3")} {empresa}{" "}
            {t("UseTerms.platformAccessMessage4")} <br />
            {t("UseTerms.platformAccessMessage5")} {empresa}{" "}
            {t("UseTerms.platformAccessMessage6")} {empresa}{" "}
            {t("UseTerms.platformAccessMessage7")} <br />
            {t("UseTerms.platformAccessMessage8")} {` ${empresa}`}{" "}
            {t("UseTerms.platformAccessMessage9")} <br />
          </p>

          <h4>
            <strong>
              {t("UseTerms.toolsUseByOrganizer")} {empresa}{" "}
              {t("UseTerms.toolsUseByOrganizer2")}
            </strong>
          </h4>
          <p>
            {t("UseTerms.toolsUseByOrganizerMessage")} {` ${empresa}`}{" "}
            {t("UseTerms.toolsUseByOrganizerMessage2")} {`${empresa} `}
            {t("UseTerms.toolsUseByOrganizerMessage3")} {empresa}{" "}
            {t("UseTerms.toolsUseByOrganizerMessage4")}
            <br />
            {t("UseTerms.toolsUseByOrganizerMessage5")}
            <br />
            {t("UseTerms.toolsUseByOrganizerMessage6")}
            <br />
            {t("UseTerms.toolsUseByOrganizerMessage7")} {empresa}.{" "}
            {t("Terms.cookiesConsideration3")} {empresa}{" "}
            {t("UseTerms.toolsUseByOrganizerMessage8")}
            <br />
            {t("UseTerms.toolsUseByOrganizerMessage9")} {empresa},{" "}
            {t("UseTerms.toolsUseByOrganizerMessage10")}
            <br />
            {t("UseTerms.toolsUseByOrganizerMessage11")}
            <br />
            {t("UseTerms.toolsUseByOrganizerMessage12")} {empresa}{" "}
            {t("UseTerms.toolsUseByOrganizerMessage13")} {empresa}{" "}
            {t("UseTerms.toolsUseByOrganizerMessage14")} {empresa}{" "}
            {t("UseTerms.toolsUseByOrganizerMessage15")}
            <br />
          </p>
          <h4>
            <strong>{t("UseTerms.ticketsBuy")}</strong>
          </h4>

          <p>
            {t("UseTerms.ticketsBuyMessage")} {empresa}{" "}
            {t("UseTerms.ticketsBuyMessage2")}
            <br />
            {t("UseTerms.ticketsBuyMessage3")}
            <br />
            {t("UseTerms.ticketsBuyMessage4")} {empresa}{" "}
            {t("UseTerms.ticketsBuyMessage5")}
            <br />
            {t("UseTerms.ticketsBuyMessage6")}
            <br />
            {t("UseTerms.ticketsBuyMessage7")}
            <br />
            {t("UseTerms.ticketsBuyMessage8")} {empresa}.
            {t("UseTerms.ticketsBuyMessage9")} {empresa}{" "}
            {t("UseTerms.ticketsBuyMessage10")}
            <br />
            {t("UseTerms.ticketsBuyMessage11")}
            <br />
            {t("UseTerms.ticketsBuyMessage12")} {` ${empresa}`}{" "}
            {t("UseTerms.ticketsBuyMessage13")}
            <br />
            {t("UseTerms.ticketsBuyMessage14")}
            <br />
            {t("UseTerms.ticketsBuyMessage15")} {empresa}{" "}
            {t("UseTerms.ticketsBuyMessage16")}
            <br />
            {t("UseTerms.ticketsBuyMessage17")} {empresa}{" "}
            {t("UseTerms.ticketsBuyMessage18")} {empresa}.
            <br />
            {t("UseTerms.ticketsBuyMessage19")} {empresa}{" "}
            {t("UseTerms.ticketsBuyMessage20")}
            <br />
            {t("UseTerms.ticketsBuyMessage21")} {` ${empresa}`}{" "}
            {t("UseTerms.ticketsBuyMessage22")}
            <br />
          </p>
          <h4>
            <strong>{t("UseTerms.serviceFee")}</strong>
          </h4>

          <p>
            {t("UseTerms.serviceFeeMessage")} {empresa}{" "}
            {t("UseTerms.serviceFeeMessage2")}
            <br />
            {t("UseTerms.serviceFeeMessage3")} {` ${empresa}`},{" "}
            {t("UseTerms.serviceFeeMessage4")}
            <br />
            {t("UseTerms.serviceFeeMessage5")}
            <br />
            {t("UseTerms.serviceFeeMessage6")} {empresa}{" "}
            {t("UseTerms.serviceFeeMessage7")}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`http://www.${empresa}.com.br/politicas/`}
            >
              {` http://www.${empresa.toLowerCase()}.com.br/politicas/`}
            </a>
            <br />
            {t("UseTerms.serviceFeeMessage8")} {empresa}{" "}
            {t("UseTerms.serviceFeeMessage9")}
            <br />
            {t("UseTerms.serviceFeeMessage10")}
            <br />
            {t("UseTerms.serviceFeeMessage11")} {empresa}{" "}
            {t("UseTerms.serviceFeeMessage12")}
            <br />
            {t("UseTerms.serviceFeeMessage13")} {empresa}{" "}
            {t("UseTerms.serviceFeeMessage14")}
            <br />
          </p>
          <h4>
            <strong>{t("UseTerms.sellsAndPayments")}</strong>
          </h4>
          <p>
            {t("UseTerms.sellsAndPaymentsMessage")} {empresa}{" "}
            {t("UseTerms.sellsAndPaymentsMessage2")} {empresa},{" "}
            {t("UseTerms.sellsAndPaymentsMessage3")} {empresa},{" "}
            {t("UseTerms.sellsAndPaymentsMessage4")} {empresa},{" "}
            {t("UseTerms.sellsAndPaymentsMessage5")}
            <br />
            {t("UseTerms.sellsAndPaymentsMessage6")} {empresa}.
            <br />
          </p>
          <h4>
            <strong>{t("UseTerms.ticketUtilization")}</strong>
          </h4>

          <p>
            {t("UseTerms.ticketUtilizationMessage")} {` ${empresa}`}{" "}
            {t("UseTerms.ticketUtilizationMessage2")}
            <br />
            {t("UseTerms.ticketUtilizationMessage3")} {empresa},
            {t("UseTerms.ticketUtilizationMessage4")}
            <br />
            {t("UseTerms.ticketUtilizationMessage5")} {empresa},{" "}
            {t("UseTerms.ticketUtilizationMessage6")}
            <br />
            {t("UseTerms.ticketUtilizationMessage7")}
            <br />
            {t("UseTerms.ticketUtilizationMessage8")} {` ${empresa}`}{" "}
            {t("UseTerms.ticketUtilizationMessage9")}
            <br />
            {t("UseTerms.ticketUtilizationMessage10")} {empresa}{" "}
            {t("UseTerms.ticketUtilizationMessage11")}
            <br />
            {t("UseTerms.ticketUtilizationMessage12")} {empresa}.
            <br />
          </p>

          <h4>
            <strong>{t("UseTerms.ownershipTransfer")}</strong>
          </h4>
          <p>
            {t("UseTerms.ownershipTransferMessage")} {empresa}.{" "}
            {t("UseTerms.ownershipTransferMessage2")} <br />
            {t("UseTerms.ownershipTransferMessage3")}
            <br />
            {t("UseTerms.ownershipTransferMessage4")} {empresa}{" "}
            {t("UseTerms.ownershipTransferMessage5")}
            <br />
            {t("UseTerms.ownershipTransferMessage6")} {email}.{" "}
            {t("UseTerms.ownershipTransferMessage7")} {empresa}.
            <br />
            {t("UseTerms.ownershipTransferMessage8")}
            <br />
            {t("UseTerms.ownershipTransferMessage9")} {` ${empresa}`}{" "}
            {t("UseTerms.ownershipTransferMessage10")} {empresa}{" "}
            {t("UseTerms.ownershipTransferMessage11")}
            <br />
            {t("UseTerms.ownershipTransferMessage12")}
            <br />
          </p>

          <h4>
            <strong>{t("UseTerms.cancellationAndRefund")}</strong>
          </h4>
          <p>
            {t("UseTerms.cancellationAndRefundMessage")}
            <br />
            {t("UseTerms.cancellationAndRefundMessage2")} {empresa}.
            <br />
            {t("UseTerms.cancellationAndRefundMessage3")}
            <br />
            {t("UseTerms.cancellationAndRefundMessage4")} {empresa}{" "}
            {t("UseTerms.cancellationAndRefundMessage5")}
            <br />
            {t("UseTerms.cancellationAndRefundMessage6")} {empresa}{" "}
            {t("UseTerms.cancellationAndRefundMessage7")}
            <br />
            {t("UseTerms.cancellationAndRefundMessage8")}
            <br />
            {t("UseTerms.cancellationAndRefundMessage9")} {empresa}{" "}
            {t("UseTerms.cancellationAndRefundMessage10")}
            <br />
            {t("UseTerms.cancellationAndRefundMessage11")} {empresa}{" "}
            {t("UseTerms.cancellationAndRefundMessage12")} {` ${empresa}`},
            {t("UseTerms.cancellationAndRefundMessage13")}
            <br />
            {t("UseTerms.cancellationAndRefundMessage14")} {empresa}{" "}
            {t("UseTerms.cancellationAndRefundMessage15")}
            <br />
            {t("UseTerms.cancellationAndRefundMessage16")} {empresa},{" "}
            {t("UseTerms.cancellationAndRefundMessage17")}
            <br />
            {t("UseTerms.cancellationAndRefundMessage18")} {` ${empresa}`}{" "}
            {t("UseTerms.cancellationAndRefundMessage19")}
            <br />
            {t("UseTerms.cancellationAndRefundMessage20")}
            <br />
          </p>

          <h4>
            <strong>{t("UseTerms.disputesAndChargebacks")}</strong>
          </h4>
          <p>
            {t("UseTerms.disputesAndChargebacksMessage")} {empresa}{" "}
            {t("UseTerms.disputesAndChargebacksMessage2")}
            <br />
            {t("UseTerms.disputesAndChargebacksMessage3")} {empresa}.
            <br />
            {t("UseTerms.disputesAndChargebacksMessage4")} {empresa}{" "}
            {t("UseTerms.disputesAndChargebacksMessage5")} {` ${empresa}`}{" "}
            {t("UseTerms.disputesAndChargebacksMessage6")}
            <br />
            {t("UseTerms.disputesAndChargebacksMessage7")}
            {` ${empresa}`} {t("UseTerms.disputesAndChargebacksMessage8")}
            <br />
            {t("UseTerms.disputesAndChargebacksMessage9")} {empresa}{" "}
            {t("UseTerms.disputesAndChargebacksMessage10")}
            <br />
            {t("UseTerms.disputesAndChargebacksMessage11")}
            <br />
            {t("UseTerms.disputesAndChargebacksMessage12")}
            <br />
            {t("UseTerms.disputesAndChargebacksMessage13")}
            <br />
            {t("UseTerms.disputesAndChargebacksMessage14")}
            <br />
            {t("UseTerms.disputesAndChargebacksMessage15")}
            <br />
          </p>
          <h4>
            {" "}
            <strong>
              {t("UseTerms.companyResponsability")} {empresa}
            </strong>
          </h4>

          <p>
            {t("UseTerms.companyResponsabilityMessage")} {empresa}{" "}
            {t("UseTerms.companyResponsabilityMessage2")} <br />
            {t("UseTerms.companyResponsabilityMessage3")} {empresa}{" "}
            {t("UseTerms.companyResponsabilityMessage4")} <br />
            {t("UseTerms.companyResponsabilityMessage5")} {empresa}{" "}
            {t("UseTerms.companyResponsabilityMessage6")} <br />
          </p>

          <h4>
            <strong>{t("UseTerms.organizerResponsabilities")}</strong>
          </h4>
          <p>
            {t("UseTerms.organizerResponsabilitiesMessage")} {` ${empresa}`},{" "}
            {t("UseTerms.organizerResponsabilitiesMessage2")} <br />
            {t("UseTerms.organizerResponsabilitiesMessage12")} {empresa}.
            <br />
            {t(
              "UseTerms.organizerResponsabilitiesMessage3"
            )} {` ${empresa}`} {t("UseTerms.organizerResponsabilitiesMessage4")}{" "}
            {empresa} {t("UseTerms.organizerResponsabilitiesMessage5")}
            <br />
            {t("UseTerms.organizerResponsabilitiesMessage6")} {empresa}{" "}
            {t("UseTerms.organizerResponsabilitiesMessage7")}
            <br />
            {t(
              "UseTerms.organizerResponsabilitiesMessage8"
            )} {` ${empresa}`} {t("UseTerms.organizerResponsabilitiesMessage9")}{" "}
            {` ${empresa} `} {t("UseTerms.organizerResponsabilitiesMessage10")}
            <br />
            {t("UseTerms.organizerResponsabilitiesMessage11")}
            <br />
          </p>
          <h4>
            <strong>{t("UseTerms.consumerResponsabilities")}</strong>
          </h4>
          <p>
            {t("UseTerms.consumerResponsabilitiesMessage")} {` ${empresa}`}{" "}
            {t("UseTerms.consumerResponsabilitiesMessage2")}
            <br />
            {t("UseTerms.consumerResponsabilitiesMessage3")} {empresa}{" "}
            {t("UseTerms.consumerResponsabilitiesMessage4")} <br />
            {t("UseTerms.consumerResponsabilitiesMessage5")}
            <br />
            {t("UseTerms.consumerResponsabilitiesMessage6")} {empresa},{" "}
            {t("UseTerms.consumerResponsabilitiesMessage7")} <br />
            {t("UseTerms.consumerResponsabilitiesMessage8")} <br />
            {t("UseTerms.consumerResponsabilitiesMessage9")} {` ${empresa}`},{" "}
            {t("UseTerms.consumerResponsabilitiesMessage10")} {` ${empresa}`}{" "}
            {t("UseTerms.consumerResponsabilitiesMessage11")} <br />
            {t("UseTerms.consumerResponsabilitiesMessage12")} {empresa}{" "}
            {t("UseTerms.consumerResponsabilitiesMessage13")}
            <br />
            {t("UseTerms.consumerResponsabilitiesMessage14")} {empresa},{" "}
            {t("UseTerms.consumerResponsabilitiesMessage15")}
            {` ${empresa}`} {t("UseTerms.consumerResponsabilitiesMessage16")}
            <br />
            {t("UseTerms.consumerResponsabilitiesMessage17")} <br />
            {t("UseTerms.consumerResponsabilitiesMessage18")}
            <br />
            {t("UseTerms.consumerResponsabilitiesMessage19")} <br />
            {t("UseTerms.consumerResponsabilitiesMessage20")} {empresa}{" "}
            {t("UseTerms.consumerResponsabilitiesMessage21")} {empresa}{" "}
            {t("UseTerms.consumerResponsabilitiesMessage22")}
            <br />
            {t("UseTerms.consumerResponsabilitiesMessage23")} {empresa},{" "}
            {t("UseTerms.consumerResponsabilitiesMessage24")} <br />
          </p>

          <h4>
            <strong>{t("UseTerms.intellectualProperty")}</strong>
          </h4>
          <p>
            {t("UseTerms.intellectualPropertyMessage")} "{empresa}"{" "}
            {t("UseTerms.intellectualPropertyMessage2")} {empresa},{" "}
            {t("UseTerms.intellectualPropertyMessage3")} {empresa}{" "}
            {t("UseTerms.intellectualPropertyMessage4")} {empresa}.
            <br />
            {t("UseTerms.intellectualPropertyMessage5")} {empresa},
            {t("UseTerms.intellectualPropertyMessage6")} {empresa}{" "}
            {t("UseTerms.intellectualPropertyMessage7")} <br />
          </p>

          <h4>
            <strong>{t("UseTerms.userPrivacy")}</strong>
          </h4>
          <p>
            {t("UseTerms.userPrivacyMessage")} {empresa}{" "}
            {t("UseTerms.userPrivacyMessage2")} {` ${empresa}`}{" "}
            {t("UseTerms.userPrivacyMessage3")} <br />
            {t("UseTerms.userPrivacyMessage4")} {empresa}{" "}
            {t("UseTerms.userPrivacyMessage5")} <br />
            {t("UseTerms.userPrivacyMessage6")}
            <br />
            {t("UseTerms.userPrivacyMessage7")}
            <br />
            {t("UseTerms.userPrivacyMessage8")} {empresa} n
            {t("UseTerms.userPrivacyMessage9")} {empresa},{" "}
            {t("UseTerms.userPrivacyMessage10")} {empresa}. <br />
            {t("UseTerms.userPrivacyMessage11")} {empresa}{" "}
            {t("UseTerms.userPrivacyMessage12")} {empresa},{" "}
            {t("UseTerms.userPrivacyMessage13")} <br />
            {t("UseTerms.userPrivacyMessage14")}
            {empresa} {t("UseTerms.userPrivacyMessage15")} {` ${empresa}`}.{" "}
            <br />
          </p>
          <h4>
            <strong>1{t("UseTerms.generalProvisions")}</strong>
          </h4>
          <p>
            {t("UseTerms.generalProvisionsMessage")} {empresa}{" "}
            {t("UseTerms.generalProvisionsMessage2")} {` ${empresa}`},{" "}
            {t("UseTerms.generalProvisionsMessage3")} <br />
            {t("UseTerms.generalProvisionsMessage4")} {empresa}{" "}
            {t("UseTerms.generalProvisionsMessage5")}
            <br />
            {t("UseTerms.generalProvisionsMessage6")} {empresa}{" "}
            {t("UseTerms.generalProvisionsMessage7")}
            <br />
            {t("UseTerms.generalProvisionsMessage8")}
            <br />
            {t("UseTerms.generalProvisionsMessage9")} {empresa}.{" "}
            {t("UseTerms.generalProvisionsMessage10")} {empresa}{" "}
            {t("UseTerms.generalProvisionsMessage11")}
            <br />
            {t("UseTerms.generalProvisionsMessage12")} {empresa}{" "}
            {t("UseTerms.generalProvisionsMessage13")}
            <br />
            {t("UseTerms.generalProvisionsMessage14")}
            <br />
            {t("UseTerms.generalProvisionsMessage15")}
            <br />
            {t("UseTerms.generalProvisionsMessage16")}
            {empresa} {t("UseTerms.generalProvisionsMessage17")} {empresa},
            {t("UseTerms.generalProvisionsMessage18")} {email}.
            <br />
            {t("UseTerms.generalProvisionsMessage19")} {empresa}{" "}
            {t("UseTerms.generalProvisionsMessage20")}
            <br />
          </p>
        </div>
      </div>
    </>
  );
}
