import * as Sentry from "@sentry/react";

const sendToLogger = (error) => {
  if (process.env.REACT_APP_SENTRY_ENVIRONMENT === "development") {
    console.log(error);
  }
  Sentry.captureException(error);
};

export default sendToLogger;
