import React from "react";
import DateHelper from "../../Helpers/date";
import ImageHandlerService from "../../services/image-handler";
import bannerDefault from "../../assets/img/bannerDefault.png";
import FavoriteButton from "../FavoriteButton";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";
import "./index.css";
import { isInZigApp } from "../../services/utils";

function Banner({ event, isCarousel, onPlaceClick }) {
  const isMobile = window.innerWidth <= 991;
  const history = useHistory();
  const { t } = useTranslation();
  const word = t("CommonExpressions.to");

  const bannerUrl =
    !event.banner || event.banner.includes("/bannerDefault")
      ? bannerDefault
      : ImageHandlerService.getImageFromUrl(event.banner, {
          format: "webp",
          resize: { height: 350, fit: "contain" },
        });

  const generateEventLocationText = (event, isCarousel) => {
    const location = event.event_location;

    if (isCarousel && location?.name) {
      return `${location.city}, ${location.name}`;
    } else {
      return `${location?.name} - ${location.formatted_address}`;
    }
  };
  const showEventHour = process.env.REACT_APP_SHOW_EVENT_HOUR === "true";

  return (
    <>
      <div
        id="main-banner"
        className={`d-flex align-items-center position-relative ${
          isInZigApp() ? "main-banner-zig" : ""
        }`}
      >
        <div
          id="banner-background"
          className="position-absolute"
          style={{ backgroundImage: `url(${bannerUrl})` }}
        ></div>
        <div
          id="banner-content"
          className={`container banner-short ${
            isInZigApp() ? "banner-short-zig" : ""
          }`}
        >
          <div
            id="banner-img"
            className="banner-img-short"
            style={{
              backgroundImage: `url(${bannerUrl})`,
              cursor: `${isCarousel ? "pointer" : "auto"}`,
            }}
            onClick={() => {
              if (isCarousel) {
                history.push(`/eventos/${event.slug}`);
              }
            }}
          ></div>

          <div className="d-flex col-sm-12 col-lg-4 banner-infos">
            <div className="name-date d-flex flex-column">
              <h2>{event.name}</h2>
              <span className="banner-date">
                {" "}
                <i className="far fa-calendar-check"></i>{" "}
                {DateHelper.format(
                  event.start_date,
                  t("CommonExpressions.dateFormat")
                )}
              </span>
            </div>

            <div className="interaction d-flex flex-row">
              <FavoriteButton
                eventId={event.id}
                eventName={event.name}
                style={{
                  fontSize: "1.8rem",
                  height: "3rem",
                  width: "3rem",
                }}
              />
            </div>

            <div className="info d-flex flex-column">
              <div>
                <span
                  className="banner-place"
                  role="button"
                  id="place-ellips-none"
                  onClick={onPlaceClick}
                >
                  <i className="far fa-compass"></i>{" "}
                  {event.is_online === 1
                    ? "Online"
                    : generateEventLocationText(event, isCarousel)}
                </span>
              </div>
              <div>
                {((isCarousel === undefined && isMobile) || !isMobile) && (
                  <span className="banner-place">
                    <i className="far fa-clock"></i>{" "}
                    {DateHelper.format(event.start_date, "HH:mm")}
                    {showEventHour
                      ? ` ${word} ${DateHelper.format(event.end_date, "HH:mm")}`
                      : ""}
                  </span>
                )}
              </div>

              {!isCarousel ? (
                event.age_range ? (
                  <span className="banner-place">
                    <i className="far fa-user"></i>{" "}
                    {t("CommonExpressions.classification")} {event.age_range}{" "}
                    {t("CommonExpressions.years")}{" "}
                  </span>
                ) : (
                  <span className="banner-place ">
                    <i className="fas fa-user"></i>{" "}
                    {t("CommonExpressions.ageRating")}{" "}
                  </span>
                )
              ) : null}
            </div>
            {isCarousel && (
              <NavLink className="nav-link-btn" to={`/eventos/${event.slug}`}>
                <i className="fas fa-ticket-alt tick-icon mr-2"></i>
                {t("CommonExpressions.buyNow")}
              </NavLink>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
