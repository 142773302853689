import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { detectorOfOS } from "../../services/utils";

import credit_card from "../../assets/svg/credit_card.svg";
import google_pay from "../../assets/svg/google_pay.svg";
import apple_pay from "../../assets/svg/apple_pay.svg";
import picpay from "../../assets/svg/picpay.svg";
import pix from "../../assets/svg/pix.svg";
import { BuyerDataInformationView } from "./PaymentFormView/BuyerDataInformationView";

const os = detectorOfOS();

const paymentIcons = {
  Cartão: credit_card,
  Débito: credit_card,
  PicPay: picpay,
  PIX: pix,
  "Google Pay": google_pay,
  "Apple Pay": apple_pay,
};

export const PaymentMethodsView = ({
  user,
  watch,
  isZig,
  errors,
  register,
  isStripe,
  orderData,
  translate,
  onSetValue,
  dataMethods,
  cardMethods,
  clearErrors,
  onZipCodeFetched,
  mountYunoCheckout,
  onInitializingYuno,
  hasAtleastOneZigPaymentMethod,
}) => {
  const handlerPaymentTypeChange = (paymentMethod) => {
    onSetValue("payment.payment_type", paymentMethod.method);
    onSetValue("payment.payment_mode", paymentMethod.payment_mode);

    if (isZig() && cardMethods.includes(paymentMethod.method)) {
      onInitializingYuno(true);
      mountYunoCheckout();
    }
  };

  const getIcon = (method) => {
    return paymentIcons[method] || "";
  };

  return (
    <div>
      {orderData?.value > 0 && (
        <Row role="radiogroup" className="d-flex flex-direction-row">
          {dataMethods?.map((paymentMethod, idx) => (
            <Col
              xs={12}
              md={6}
              key={idx}
              onClick={() => handlerPaymentTypeChange(paymentMethod)}
              className={
                (paymentMethod.method === "Apple Pay" &&
                  !["macOS", "iOS"].includes(os)) ||
                (["Apple Pay", "Google Pay"].includes(paymentMethod.method) &&
                  !hasAtleastOneZigPaymentMethod)
                  ? "d-none"
                  : ""
              }
            >
              <Form.Group>
                <div className="btn custom-radio">
                  <Form.Check
                    id={"payment_type" + idx}
                    inline={true}
                    type="radio"
                    value={paymentMethod.method}
                    checked={
                      watch("payment.payment_type") === paymentMethod.method
                    }
                    {...register("payment.payment_type")}
                  />
                  <Form.Label className="form-check-label">
                    {paymentMethod.method === "Cartão" && (
                      <>{translate("Checkout.creditCard")}</>
                    )}
                    {paymentMethod.method === "Débito" && (
                      <>{translate("Checkout.debitCard")}</>
                    )}
                    {!["Cartão", "Débito"].includes(paymentMethod.method) && (
                      <>{paymentMethod.method}</>
                    )}
                  </Form.Label>

                  {!!getIcon(paymentMethod.method) && (
                    <img
                      src={getIcon(paymentMethod.method)}
                      alt="payment icon"
                    />
                  )}
                </div>
              </Form.Group>
            </Col>
          ))}
        </Row>
      )}

      {((!isZig() && !isStripe()) || orderData?.value === 0) && (
        <BuyerDataInformationView
          translate={translate}
          errors={errors}
          onSetValue={onSetValue}
          clearErrors={clearErrors}
          onZipCodeFetched={onZipCodeFetched}
          user={user}
          register={register}
        />
      )}
    </div>
  );
};
