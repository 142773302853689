/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const CountDownView = ({ expiresIn, secondsToExpires, onTimesUp }) => {
  const [countDown, setCountDown] = useState();
  const [countValue, setCountValue] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (expiresIn && secondsToExpires) {
      count(expiresIn, secondsToExpires);
    }
  }, [expiresIn, secondsToExpires]);

  useEffect(() => {
    return () => {
      if (countDown) {
        clearTimeout(countDown);
      }
    };
  }, [countDown]);

  function count(expiresIn, secondsToExpires) {
    const timeToExit = dayjs(expiresIn).diff(dayjs(), "s");

    if (timeToExit <= 0 || secondsToExpires <= 0) {
      setCountValue("-1");
      onTimesUp();
    } else {
      var minutes = parseInt(timeToExit / 60);
      var seconds = timeToExit % 60;

      if (minutes < 10) {
        minutes = "0" + minutes;
        minutes = minutes.substr(0, 2);
      }

      if (seconds <= 9) {
        seconds = "0" + seconds;
      }

      setCountValue(minutes + ":" + seconds);

      setCountDown(
        setTimeout(() => {
          count(expiresIn, secondsToExpires - 1);
        }, 1000)
      );
    }
  }

  return (
    <>
      {countValue && (
        <div id="cron-div" className="cron-clock-icon">
          <FontAwesomeIcon icon={faClock} />
          <h4 className="mb-0 ml-2">
            {countValue === "-1"
              ? t("CountdownView.expiredOrder")
              : `${t("CountdownView.checkoutExpiresIn")} ${countValue}`}
          </h4>
        </div>
      )}
    </>
  );
};

export default CountDownView;
