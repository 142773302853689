/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../../components/Loader";
import { useAuth } from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useZendesk } from "../../hooks/useZendesk";

export default function Index({ history }) {
  const { provider } = useParams();
  const { processSocialAuth } = useAuth();
  const { t } = useTranslation();
  const { hide } = useZendesk();

  useEffect(() => {
    const run = async () => {
      const params = window.location.search;

      if (params.includes("code")) {
        return await processSocialAuth(params, provider);
      }

      return false;
    };

    const runPush = () => {
      const nextTo = localStorage.getItem("auth_next_to");
      localStorage.removeItem("auth_next_to");

      if (nextTo) {
        window.location.href = nextTo;
      } else {
        history.push("/");
      }
    };

    run()
      .then((result) => {
        if (!result) {
          Swal.fire({
            icon: "error",
            title: t("CommonExpressions.ops"),
            message: t("SocialAuth.noAccessProviderFound"),
          }).then(runPush);
        } else {
          runPush();
        }
      })
      .catch(runPush);

    hide();
  }, []);

  return (
    <div className="loading-container">
      <Loader
        title={t("Index.wait")}
        text={t("SocialAuth.processingCredentials")}
      />
    </div>
  );
}
