import React, { useState } from "react";
import { PropTypes } from "prop-types";
import MaskHelper from "../../Helpers/mask";
import "./styles.css";
import useWindowSize from "../../hooks/useWindowSize";
import DiscountCoupon from "../DiscountCoupon";
import { findDescription } from "../../services/constants";
import { useTranslation } from "react-i18next";
function SeatsPageFooter({
  totalCartValue,
  seats,
  onApplyClick,
  isLoading,
  onLoading,
  onOpenSidebar,
  sidebarOpen,
  onPayment,
  isCodeApplied,
  onAppliedCode,
  tickets,
  isDiscountInvalidForAll,
  isInvalidDiscount,
  event,
}) {
  const currencyMask = MaskHelper.createCurrencyMask("pt-BR", "BRL");
  const [discountDescription, setDiscountDescription] = useState("");
  const [codePromo, setCodePromo] = useState("");
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const stewardCode = urlParams.get("code");
  const urlDiscountCoupon = urlParams.get("cupom");

  const isMobile = useWindowSize();

  const couponDescription = findDescription(
    event.discount_code,
    event.discount
  );

  if (couponDescription) {
    setDiscountDescription(couponDescription);
  }

  const handlePaymentClick = async () => {
    const shouldOpenSidebar = isMobile && seats.length > 0;

    if (seats.length === 0) {
      return false;
    }

    if (urlDiscountCoupon) {
      await onApplyClick(urlDiscountCoupon);
      onPayment(seats, false, urlDiscountCoupon);
    }

    if (shouldOpenSidebar) {
      onOpenSidebar((prevState) => !prevState);
    }

    if (!shouldOpenSidebar || sidebarOpen) {
      onPayment(seats);
    }
  };

  return (
    <div className="container-fluid seats-page-footer d-flex position-sticky justify-content-end">
      <div
        className={`row seats-page-footer-content ${
          isMobile && sidebarOpen ? "pb-2" : "py-2"
        }`}
      >
        <div
          className={`py-3 pl-3 ${
            isMobile && !sidebarOpen ? "" : "discount-component-container seats"
          }`}
        >
          <div
            className={`col-12 p-0 ${
              sidebarOpen && isMobile ? "d-flex" : "d-none d-md-flex d-lg-flex"
            }`}
          >
            <DiscountCoupon
              isSeatsPage={true}
              stewardCode={stewardCode}
              seatsOnHandleApply={onApplyClick}
              isLoading={isLoading}
              onLoading={onLoading}
              seatsCodePromo={codePromo}
              seatsOnChangeCodePromo={setCodePromo}
              isCodeApplied={isCodeApplied}
              onAppliedCode={onAppliedCode}
              tickets={tickets}
              discountCouponDescription={discountDescription}
              isDiscountInvalidForAll={isDiscountInvalidForAll}
              isInvalidDiscount={isInvalidDiscount}
              urlCoupon={urlDiscountCoupon}
            />
          </div>
        </div>

        <div className="seats-payment-container pr-2">
          <div>
            <span className="seats-page-cart-value-text">
              {currencyMask(totalCartValue || 0)}
            </span>
          </div>
          <div>
            <button
              className="btn payment-button d-flex py-3 px-0 flex-column justify-content-center align-items-center text-center position-relative"
              onClick={handlePaymentClick}
              disabled={seats.length === 0}
            >
              <span className="payment-badge d-flex d-md-none d-lg-none position-absolute justify-content-center align-items-center">
                {seats?.length || 0}
              </span>
              {isMobile && !sidebarOpen
                ? t("SeatsFooter.ticket")
                : t("SeatsFooter.goToPayment")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

SeatsPageFooter.propTypes = {
  totalCartValue: PropTypes.number.isRequired,
  seats: PropTypes.any.isRequired,
  eventInfo: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  onLoading: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onPayment: PropTypes.func.isRequired,
};

export default SeatsPageFooter;
