import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

function CountDown({ expiresIn, timeToExpire, onFinishTimer }) {
  const [printTime, setPrintTime] = useState("");
  const { t } = useTranslation();
  let countDown;

  useEffect(() => {
    if (expiresIn && timeToExpire) {
      startCron(expiresIn, timeToExpire);
    }

    return () => {
      clearTimeout(countDown);
    };
    // eslint-disable-next-line
  }, [expiresIn, timeToExpire]);

  function startCron(expiresIn, timeToExpire) {
    const timeToExit = dayjs(expiresIn).diff(dayjs(), "s");
    if (timeToExit <= 0 || timeToExpire <= 0) {
      finalizeCardByTimeOut();
    } else {
      const minutes = Math.floor(timeToExit / 60);
      const seconds = timeToExit % 60;

      let formattedMinutes = minutes < 10 ? "0" + minutes : minutes.toString();
      let formattedSeconds = seconds <= 9 ? "0" + seconds : seconds.toString();

      const printedTime = formattedMinutes + ":" + formattedSeconds;
      setPrintTime(printedTime);

      countDown = setTimeout(() => {
        startCron(expiresIn, timeToExpire - 1);
      }, 1000);
    }
  }

  function finalizeCardByTimeOut() {
    Swal.fire({
      icon: "error",
      title: t("Countdown.purchaseExpired"),
      text: t("Countdown.expiredExplanation"),
      didClose: () => onFinishTimer(),
    });
  }

  return <span>{printTime}</span>;
}

export default CountDown;
