import { atom } from "recoil";

const location = new URLSearchParams(window.location.search);

export const searchInputText = atom({
  key: "searchStore",
  default: {
    search: location.get("s"),
    state: location.get("st"),
  },
});
