export default class MaskHelper {
  static createCurrencyMask(locale, currency) {
    const formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    });

    return function (amount) {
      return formatter.format(amount);
    };
  }

  static numberToLocaleCurrency(number) {
    return (number || 0).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  static numberMask(value) {
    return value?.replace(/\D/g, "");
  }

  static alphanumericMask(value) {
    return value?.replace(/[^a-z0-9]/gi, "");
  }

  static cpfMask(value) {
    return value
      ?.replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d?$/, "$1");
  }

  static dateMask(value) {
    return value
      ?.replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{4})\d+?$/, "$1");
  }

  static cardExpirationMask(value) {
    return value
      ?.replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})\d+?$/, "$1");
  }

  static commonCardMask(value) {
    return value
      ?.replace(/\D/g, "")
      .replace(/(\d{4})(\d)/, "$1 $2")
      .replace(/(\d{4})(\d)/, "$1 $2")
      .replace(/(\d{4})(\d)/, "$1 $2")
      .replace(/(\d{4})\d+?$/, "$1");
  }

  static phoneMask(value) {
    if (MaskHelper.numberMask(value)?.length <= 10) {
      return value
        ?.replace(/\D/g, "")
        .replace(
          /(\d{2})?(\d{0,4})?(\d{0,})?/,
          function (_, ddd, firsts, lasts) {
            return `${ddd ? "(" + ddd + ")" : ""} ${firsts || ""}${
              lasts?.slice(0, 4) ? "-" + lasts?.slice(0, 4) : ""
            }`;
          }
        );
    }

    return value
      ?.replace(/\D/g, "")
      .replace(/(\d{2})?(\d{0,5})?(\d{0,})?/, function (_, ddd, firsts, lasts) {
        return `${
          ddd ? "(" + ddd + ")" : ""
        } ${firsts || ""}${lasts?.slice(0, 4) ? "-" + lasts?.slice(0, 4) : ""}`;
      });
  }

  static cnpjMask(value) {
    return value
      ?.replace(/\D/g, "")
      .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
      .substr(0, 18);
  }

  static brazilianDocumentMask(value) {
    if (MaskHelper.numberMask(value)?.length <= 11) {
      return MaskHelper.cpfMask(value);
    }

    return MaskHelper.cnpjMask(value);
  }

  static zipcodeMask(value) {
    return value
      ?.replace(/\D/g, "")
      .replace(/(\d{5})(\d{1,3})/, "$1-$2")
      .replace(/(-\d{3})\d?$/, "$1");
  }
}
