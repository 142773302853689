import sendToLogger from "../Helpers/errorLogger";
import IntegrationApi from "./integration-api";

const api = new IntegrationApi();

export default class MoengageService {
  static async getUser(body) {
    try {
      if (process.env.REACT_APP_ENABLE_MOENGAGE) {
        api.post("/moengage/user/get", body);
      }
    } catch (err) {
      sendToLogger(err);
    }
  }
  static async trackUser(body) {
    try {
      if (process.env.REACT_APP_ENABLE_MOENGAGE) {
        api.post("/moengage/user/track", body);
      }
    } catch (err) {
      sendToLogger(err);
    }
  }
  static async mergeUser(body) {
    try {
      if (process.env.REACT_APP_ENABLE_MOENGAGE) {
        api.post("/moengage/user/merge", body);
      }
    } catch (err) {
      sendToLogger(err);
    }
  }
  static async deleteUser(body) {
    try {
      if (process.env.REACT_APP_ENABLE_MOENGAGE) {
        api.post("/moengage/user/delete", body);
      }
    } catch (err) {
      sendToLogger(err);
    }
  }
  static async createEvent(body) {
    try {
      if (process.env.REACT_APP_ENABLE_MOENGAGE) {
        api.post("/moengage/event/create", body);
      }
    } catch (err) {
      sendToLogger(err);
    }
  }
}
