import sendToLogger from "../Helpers/errorLogger";
import CheckoutApi from "./checkout-api";

const PAGSEGURO_CHECKOUT_SDK =
  "https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js";

class PagseguroService {
  loadScript = () => {
    return new Promise((resolve) => {
      if (document.querySelector(`script[src="${PAGSEGURO_CHECKOUT_SDK}"]`)) {
        resolve();
      } else {
        const tag = document.createElement("script");

        tag.type = "text/javascript";
        tag.src = PAGSEGURO_CHECKOUT_SDK;
        tag.onload = () => {
          resolve();
        };

        document.body.appendChild(tag);
      }
    });
  };

  buildSession = async (eventId) => {
    const checkoutApi = new CheckoutApi();

    try {
      const { data } = await checkoutApi.post("/session", {
        event_id: eventId,
        seller_name: process.env.REACT_APP_CHECKOUT_SELLER_NAME,
      });

      window["PagSeguro"].setUp({
        session: data.session,
        env: process.env.REACT_APP_PAGSEGURO_ENVIRONMENT,
      });

      return data.session;
    } catch (err) {
      sendToLogger(err);
      return;
    }
  };

  cardTokenizer = (cardNumber, holderName, securityCode, expiration) => {
    return new Promise((resolve, reject) => {
      const dateParts = expiration.split("/");

      const result = window["PagSeguro"].encryptCard({
        publicKey: process.env.REACT_APP_PAGSEGURO_ENCRYPTION_KEY,
        holder: holderName,
        number: cardNumber.replace(/\D/g, ""),
        expMonth: dateParts[0],
        expYear: "20" + dateParts[1],
        securityCode,
      });

      if (result.hasErrors) {
        reject(result.errors);
      } else {
        resolve(result.encryptedCard);
      }
    });
  };

  threeDSecure = (
    customerData,
    address,
    paymentData,
    challangeRequireCallback
  ) => {
    const phoneNumber = customerData.phone.replace(/\D/g, "");

    const request = {
      data: {
        customer: {
          name: customerData.name.replace(/[^a-z ]/gi, ""),
          email: customerData.email,
          phones: [
            {
              country: "55",
              area: phoneNumber.substring(0, 2),
              number: phoneNumber.substring(2),
              type: "MOBILE",
            },
          ],
        },
        paymentMethod: {
          type: paymentData.type,
          installments: paymentData.installments,
          card: { encrypted: paymentData.cardToken },
        },
        amount: {
          value: parseInt(paymentData.amount),
          currency: "BRL",
        },
        billingAddress: {
          street: address.street,
          number: address.number,
          regionCode: address.state,
          country: "BRA",
          city: address.city,
          postalCode: address.zip_code.replace(/\D/g, ""),
        },
        dataOnly: false,
      },
      beforeChallenge: (result) => {
        challangeRequireCallback();
        result.open();
      },
    };

    return window["PagSeguro"].authenticate3DS(request);
  };
}

export default PagseguroService;
