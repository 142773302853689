import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

const TicketNameView = ({ ticket }) => {
  const { t } = useTranslation();

  if (!ticket.seat_name) {
    return <Fragment>{ticket.name}</Fragment>;
  }

  const [sector, row, seat] = ticket.seat_name.split("-");

  if (ticket.seat_group_name) {
    return (
      <Fragment>
        {`${sector} - ${ticket.seat_group_name} | ${ticket.seat_name}`}
        <br />
        {ticket.name}
      </Fragment>
    );
  }

  if (row && seat) {
    return (
      <Fragment>
        {`${sector} - ${t("TicketNameView.line")} ${row} | ${t(
          "TicketNameView.seat"
        )} ${seat}`}
        <br />
        {ticket.name}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {ticket.seat_name}
      <br />
      {ticket.name}
    </Fragment>
  );
};

export default TicketNameView;
