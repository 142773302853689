import React, { Fragment, useMemo } from "react";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { group } from "radash";
import close_x from "../../assets/svg/generic_close.svg";
import "./styles.css";

function SeatsCartSidebar({ isOpen, seats, onClose, isLoggedAndElegible }) {
  const groupedSeats = useMemo(() => {
    return group(seats, (seat) => seat?.selectedSeat?.category?.label);
  }, [seats]);

  const handleRemoveTicket = async (selectedSeat) => {
    await selectedSeat.deselect(selectedSeat.selectedTicketType);

    if (seats.length <= 1) {
      onClose(false);
    }
  };

  return (
    <div
      className={`d-flex position-absolute align-items-start flex-column overflow-auto ${
        isOpen && Object.entries(groupedSeats).length > 0
          ? "seats-sidebar "
          : "seats-sidebar close"
      }`}
    >
      {Object.entries(groupedSeats).map(([sector, seatsInSector], index) => (
        <Fragment key={index}>
          <div
            className="d-flex p-4 align-items-center justify-content-start flex-row w-100 seats-sidebar-sector"
            key={`sector-${index}`}
          >
            <span className="sector-name" style={{ flex: 1 }}>
              {sector}
            </span>

            {index === 0 && (
              <button
                onClick={() => onClose(false)}
                type="button"
                className="close"
                aria-label="Close"
              >
                <img
                  src={close_x}
                  alt="close icon"
                  className="x-close"
                  aria-hidden="true"
                ></img>
              </button>
            )}
          </div>
          <div
            className="d-flex w-100 flex-wrap p-4 flex-column seats-sidebar-tickets"
            key={`tickets-info-${index}`}
          >
            {seatsInSector.map((seat, index) => (
              <Fragment key={index}>
                <div className="d-flex justify-content-between">
                  <div
                    className={`${
                      seatsInSector.length > 1 ? "pb-3" : ""
                    } d-flex flex-column align-items-start`}
                    key={`ticket-${index}`}
                  >
                    <span className="ticket-label">{seat.selectedSeat.id}</span>
                    <span className="ticket-type">
                      {seat.selectedSeat.selectedTicketType}
                    </span>
                    {seat.selectedTicketTypes.originalDescription && (
                      <span className="ticket-original-price">
                        {seat.selectedTicketTypes.originalDescription}
                      </span>
                    )}
                    <span
                      className={
                        seat.selectedTicketTypes.originalDescription
                          ? "ticket-price-discount"
                          : "ticket-price"
                      }
                    >
                      {seat.selectedTicketTypes.description}
                    </span>
                    {isLoggedAndElegible &&
                      !seat.selectedTicketTypes.originalDescription && (
                        <span className="ticket-warning">
                          * Cupom não aplicável a este ingresso!
                        </span>
                      )}
                  </div>
                  <div className="d-flex align-items-center justify-content-center ">
                    <div
                      className="align-items-center justify-content-center d-flex"
                      key={`remove-ticket-${index}`}
                    >
                      <button
                        className="btn"
                        onClick={() => handleRemoveTicket(seat.selectedSeat)}
                        key={`remove-ticket-button-${index}`}
                      >
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          color="#E63757"
                          size="lg"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        </Fragment>
      ))}
    </div>
  );
}

SeatsCartSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  seats: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SeatsCartSidebar;
