import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseButton } from "../../assets/svg/close-circle.svg";
import "./styles.css";
import QRCode from "react-qr-code";

function DownloadAppModal({ isOpen, handleClose, seeTicket }) {
  const { t } = useTranslation();

  const REDIRECT_MOBILE_URL = process.env.REACT_APP_MOBILE_REDIRECT_URL;

  return (
    <Modal show={isOpen}>
      <CloseButton
        height={24}
        width={24}
        style={{ display: "flex", alignSelf: "flex-end", cursor: "pointer" }}
        onClick={handleClose}
      />
      <Modal.Title className="download-modal-title">
        {t("DownloadApp.pointYourCamera")}
      </Modal.Title>
      <Modal.Body className="modal-body-download-app">
        <QRCode
          value={`${REDIRECT_MOBILE_URL}${
            seeTicket ? "meus-ingressos" : "download"
          }`}
          className="qr-code-download-app"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="close-button-download-app" onClick={handleClose}>
          {t("DownloadApp.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DownloadAppModal;
