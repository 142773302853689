import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import swal from "sweetalert2";
import sendToLogger from "../../../Helpers/errorLogger";
import MaskHelper from "../../../Helpers/mask";
import Loader from "../../../components/Loader";
import KondutoService from "../../../services/konduto";
import NewApi from "../../../services/new-api";
import "./index.css";
import PasswordForm from "./password-form";
import ProfileForm from "./profile-form";

import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useZendesk } from "../../../hooks/useZendesk";

const api = new NewApi();

export default function Index() {
  const { t } = useTranslation();
  const { hide } = useZendesk();
  const [loading, setLoading] = useState(true);
  const [userProfileData, setUserProfileData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    document: "",
    phone: "",
    birthdate: "",
    gender: "",
    instagram: "",
    xTwitter: "",
    facebook: "",
    address: {
      zip_code: "",
      street: "",
      neighborhood: "",
      complement: "",
      state: "",
      city: "",
      number: "",
    },
  });

  useEffect(() => {
    hide();

    window.scrollTo(0, 0);

    api
      .get("users/me")
      .then(({ data }) => {
        setUserProfileData({
          email: data.email,
          firstName: data.first_name,
          lastName: data.last_name,
          document: MaskHelper.brazilianDocumentMask(data.document),
          phone: MaskHelper.phoneMask(data.telephone || ""),
          birthdate: data.birthdate
            ? dayjs(data.birthdate, "YYYY-MM-DD").format("DD/MM/YYYY")
            : "",
          gender: data.gender,
          instagram: data.instagram || "",
          xTwitter: data.x_twitter || "",
          facebook: data.facebook || "",
          zipcode: data.address?.zip_code || "",
          street: data.address?.street || "",
          neighborhood: data.address?.neighborhood || "",
          complement: data.address?.complement || "",
          state: data.address?.state || "",
          city: data.address?.city || "",
          number: data.address?.number || "",
        });

        setLoading(false);
      })
      .catch((err) => {
        sendToLogger(err);

        swal.fire({
          icon: "error",
          title: t("FaleForm.oops"),
          text: t("Profile.errorFetchingProfileData"),
        });
      });

    KondutoService.sendEvent("page", "account");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div className="loading-container"></div>
          <Loader></Loader>
        </>
      ) : (
        <div className="container">
          <Tabs className="mb-4 mt-4" defaultActiveKey="profile">
            <Tab eventKey="profile" title={t("Profile.profile")}>
              <ProfileForm profileValues={userProfileData} />
            </Tab>
            <Tab eventKey="password" title={t("Profile.password")}>
              <PasswordForm />
            </Tab>
          </Tabs>
        </div>
      )}
    </>
  );
}
