export default class Translations {
  static fields = (t) => {
    return {
      email: t("Checkout.email"),
      first_name: t("CommonExpressions.name"),
      last_name: t("CommonExpressions.lastName"),
      telephone: t("Checkout.phone"),
      birthdate: t("CommonExpressions.birthdate"),
      password: t("CommonExpressions.password"),
      document: t("CommonExpressions.CPF"),
      new_password: t("PasswordForm.newPassword"),
      new_password_confirmation: t("PasswordForm.confirmNewPassword"),
    };
  };

  static messages = (t) => {
    return {
      "deve ter sido aceito": t("DefaultValidation.accepted"),
      "deve ser uma data válida": t("DefaultValidation.date"),
      "deve ser um e-mail válido.": t("DefaultValidation.email"),
      "informado é invalido.": t("DefaultValidation.informedIsInvalid"),
      "informado não é válido.": t("DefaultValidation.informedIsNotValid"),
      "o valor informado deve ser maior que 0.": t(
        "DefaultValidation.greaterThenZero"
      ),
      "é obrigatório e está faltando.": t("DefaultValidation.requiredWhen"),
      "é inválido.": t("DefaultValidation.invalid"),
      "já existe e precisa ser único.": t("DefaultValidation.unique"),
      "o valor informado não existe.": t("DefaultValidation.exists"),
    };
  };
}
