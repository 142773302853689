/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sendToLogger from "../../Helpers/errorLogger";
import placeIcon from "../../assets/img/place.png";
import bannerDefault from "../../assets/img/producer-banner.jpg";
import EventGridContainer from "../../components/Events/EventGridContainer";
import Loader from "../../components/Loader";
import NewApi from "../../services/new-api";
import "./index.css";
import { removeCheckoutData } from "../../Helpers/checkout";
import { useTranslation } from "react-i18next";
import { useZendesk } from "../../hooks/useZendesk";

export default function Index() {
  const { zip_code } = useParams();
  const { t } = useTranslation();
  const { hide } = useZendesk();
  const [events, setEvent] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    new NewApi()
      .get("events", { params: { by_zipcode: zip_code } })
      .then((res) => {
        setEvent(res.data.data);
        setLoading(true);
      })
      .catch((error) => {
        sendToLogger(error);
      });

    removeCheckoutData();
  }, [zip_code]);

  useEffect(() => hide(), []);

  return !loading ? (
    <>
      <div className="loading-container" />
      <Loader />
    </>
  ) : (
    <>
      <div
        id="productor-head"
        className="background"
        style={{ backgroundImage: `url(${bannerDefault})` }}
      />

      <div
        className="productor-avatar background"
        style={{ backgroundImage: `url(${placeIcon})` }}
      />

      <div id="main-events" className="container">
        <div className="events-list">
          {events.length > 0 ? (
            <div className="title-productor text-center">
              {events[0].event_location?.name && (
                <h2 className="text-center">{events[0].event_location.name}</h2>
              )}
              <h2 className="text-center">
                {events[0].event_location.formatted_address}
              </h2>
            </div>
          ) : (
            <div className="title-productor ajust-center  text-center">
              <h2 className="text-center">
                {t("EventsLocal.noEventsFoundLocation")}
              </h2>
            </div>
          )}
          <div id="main-events" className="container">
            <div className="events-list">
              {events.length > 0 ? (
                <div className="title-productor text-center">
                  <h5 className="text-center">
                    {t("EventsLocal.noEventsFoundLocationNext")}
                  </h5>
                </div>
              ) : (
                <div className="title-productor ajust-center  text-center">
                  <h2 className="text-center">
                    {t("EventsLocal.noEventsFoundLocationHere")}
                  </h2>
                </div>
              )}
              <div className="event-list-row">
                <EventGridContainer events={events} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
