import pagarme from "pagarme";

class PagarMeService {
  cardTokenizer = (cardNumber, holderName, securityCode, expirationDate) => {
    const data = {
      card_holder_name: holderName,
      card_expiration_date: expirationDate,
      card_number: cardNumber,
      card_cvv: securityCode,
    };

    return pagarme.client
      .connect({ encryption_key: process.env.REACT_APP_PAGARME_ENCRYPTION_KEY })
      .then((client) => client.security.encrypt(data));
  };
}

export default PagarMeService;
