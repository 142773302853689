import React from "react";

export const YunoView = ({ isZig, orderData, walletMethods, watch }) => {
  return (
    <div
      className={
        isZig() &&
        orderData?.value > 0 &&
        !walletMethods.includes(watch("payment.payment_type"))
          ? "d-block"
          : "d-none"
      }
    >
      <div id="yuno-payment-form"></div>
    </div>
  );
};
