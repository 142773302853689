/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useZendesk } from "../../../hooks/useZendesk";

export default function PrivacyTerms() {
  const empresa = process.env.REACT_APP_NAME_EMPRESA;
  const link = process.env.REACT_APP_LINK_EMPRESA;
  const email = process.env.REACT_APP_MAIL_EMPRESA;
  const { t } = useTranslation();
  const { hide } = useZendesk();

  useEffect(() => hide(), []);

  return (
    <div className="container mt-4 mb-4">
      <div className="row terms">
        <h1>{t("PrivacyPolitics.privacyPoliticsTitle")}</h1>
        <p>
          {t("PrivacyPolitics.privacyPoliticsMessage")} {empresa}!{" "}
          {t("PrivacyPolitics.privacyPoliticsMessage2")}
          <br></br>
          <br></br>
          {t("PrivacyPolitics.privacyPoliticsMessage3")} <br></br>
          <br></br>
          {t("PrivacyPolitics.privacyPoliticsMessage4")}
        </p>
        <h4>
          <strong>{t("PrivacyPolitics.definitions")}</strong>
        </h4>
        <p>
          <b>{t("PrivacyPolitics.definitionsMessage1")}</b>{" "}
          {t("PrivacyPolitics.definitionsMessage2")}
          <br></br>
          <b>{t("PrivacyPolitics.definitionsMessage3")}</b>{" "}
          {t("PrivacyPolitics.definitionsMessage4")}
          <br></br>
          <b>{t("PrivacyPolitics.definitionsMessage5")}</b>{" "}
          {t("PrivacyPolitics.definitionsMessage6")}
          <br></br>
          <b>{t("PrivacyPolitics.definitionsMessage7")}</b>{" "}
          {t("PrivacyPolitics.definitionsMessage8")}
          <br></br>
          <b>{t("PrivacyPolitics.definitionsMessage9")}</b>{" "}
          {t("PrivacyPolitics.definitionsMessage10")}
          <br></br>
          <b>{t("PrivacyPolitics.definitionsMessage11")}</b>{" "}
          {t("PrivacyPolitics.definitionsMessage12")}
          <br></br>
          <b>{t("PrivacyPolitics.definitionsMessage13")}</b>{" "}
          {t("PrivacyPolitics.definitionsMessage14")} {empresa}{" "}
          {t("PrivacyPolitics.definitionsMessage15")}
          <br></br>
          <b>{t("PrivacyPolitics.definitionsMessage16")}</b>{" "}
          {t("PrivacyPolitics.definitionsMessage17")}
          <br></br>
          <b>
            {t("PrivacyPolitics.definitionsMessage18")} {empresa}:
          </b>{" "}
          {t("PrivacyPolitics.definitionsMessage19")}
          {link}.<br></br>
          <b>{t("PrivacyPolitics.definitionsMessage20")}</b>{" "}
          {t("PrivacyPolitics.definitionsMessage21")}
          <br></br>
          <b>{t("PrivacyPolitics.definitionsMessage22")}</b>{" "}
          {t("PrivacyPolitics.definitionsMessage23")} {empresa}.<br></br>
        </p>
        <h4>
          <strong>{t("PrivacyPolitics.personalInfo")}</strong>
        </h4>
        <p>
          {t("PrivacyPolitics.personalInfoMessage")} {empresa},{" "}
          {t("PrivacyPolitics.personalInfoMessage2")} {empresa}{" "}
          {t("PrivacyPolitics.personalInfoMessage3")} {empresa}{" "}
          {t("PrivacyPolitics.personalInfoMessage4")} {empresa}{" "}
          {t("PrivacyPolitics.personalInfoMessage5")}
          <br></br>
          {t("PrivacyPolitics.personalInfoMessage6")} {empresa}{" "}
          {t("PrivacyPolitics.personalInfoMessage7")} {empresa}{" "}
          {t("PrivacyPolitics.personalInfoMessage8")} {empresa}.{" "}
          {t("PrivacyPolitics.personalInfoMessage9")}
          <br></br>
          {t("PrivacyPolitics.personalInfoMessage10")} {empresa},{" "}
          {t("PrivacyPolitics.personalInfoMessage11")}
          <br></br>
          {t("PrivacyPolitics.personalInfoMessage12")} {empresa}{" "}
          {t("PrivacyPolitics.personalInfoMessage13")} {empresa}.
          {t("PrivacyPolitics.personalInfoMessage14")} {empresa}{" "}
          {t("PrivacyPolitics.personalInfoMessage15")}
          <br></br>
          {t("PrivacyPolitics.personalInfoMessage16")} {empresa}{" "}
          {t("PrivacyPolitics.personalInfoMessage17")}
          <br></br>
          {t("PrivacyPolitics.personalInfoMessage18")}
          <br></br>
          {t("PrivacyPolitics.personalInfoMessage19")} {empresa}{" "}
          {t("PrivacyPolitics.personalInfoMessage20")} <br></br>
          {t("PrivacyPolitics.personalInfoMessage21")} {empresa}{" "}
          {t("PrivacyPolitics.personalInfoMessage22")}
          <br></br>
          {t("PrivacyPolitics.personalInfoMessage23")} {empresa},{" "}
          {t("PrivacyPolitics.personalInfoMessage24")}
          <br></br>
          {t("PrivacyPolitics.personalInfoMessage25")}
          <br></br>
          {t("PrivacyPolitics.personalInfoMessage26")} {empresa}{" "}
          {t("PrivacyPolitics.personalInfoMessage27")}
          <br></br>
          {t("PrivacyPolitics.personalInfoMessage28")} {empresa}{" "}
          {t("PrivacyPolitics.personalInfoMessage29")}
          <br></br>
          {t("PrivacyPolitics.personalInfoMessage30")} {empresa}{" "}
          {t("PrivacyPolitics.personalInfoMessage31")}
          <br></br>
          {t("PrivacyPolitics.personalInfoMessage32")} {empresa}{" "}
          {t("PrivacyPolitics.personalInfoMessage33")} {empresa}),{" "}
          {t("PrivacyPolitics.personalInfoMessage34")}
          <br></br>
        </p>
        <h4>
          <strong>{t("PrivacyPolitics.personalInfoUtilization")}</strong>
        </h4>
        <p>
          {t("PrivacyPolitics.personalInfoUtilizationMessage")}
          <br></br>
          {t("PrivacyPolitics.personalInfoUtilizationMessage2")} {empresa}{" "}
          {t("PrivacyPolitics.personalInfoUtilizationMessage3")}
          <br></br>
          {t("PrivacyPolitics.personalInfoUtilizationMessage4")}
          <br></br>
          {t("PrivacyPolitics.personalInfoUtilizationMessage5")} {empresa},{" "}
          {t("PrivacyPolitics.personalInfoUtilizationMessage6")} {empresa};
          <br></br>
          {t("PrivacyPolitics.personalInfoUtilizationMessage7")} {empresa},
          {t("PrivacyPolitics.personalInfoUtilizationMessage8")} {empresa};
          <br></br>
          {t("PrivacyPolitics.personalInfoUtilizationMessage9")}
          <br></br>
          {t("PrivacyPolitics.personalInfoUtilizationMessage10")} {empresa}{" "}
          {t("PrivacyPolitics.personalInfoUtilizationMessage11")}
          <br></br>
          {t("PrivacyPolitics.personalInfoUtilizationMessage12")} {empresa}{" "}
          {t("PrivacyPolitics.personalInfoUtilizationMessage13")} {empresa}{" "}
          {t("PrivacyPolitics.personalInfoUtilizationMessage14")} {empresa}){" "}
          {t("PrivacyPolitics.personalInfoUtilizationMessage15")} {empresa}){" "}
          {t("PrivacyPolitics.personalInfoUtilizationMessage16")}
          {email}.<br></br>
          {t("PrivacyPolitics.personalInfoUtilizationMessage17")}
          <br></br>
          {t("PrivacyPolitics.personalInfoUtilizationMessage18")} {empresa}.
          {t("PrivacyPolitics.personalInfoUtilizationMessage19")} {email}{" "}
          {t("PrivacyPolitics.personalInfoUtilizationMessage20")}
          <br></br>
          <br></br>
        </p>
        {t("PrivacyPolitics.personalInfoUtilizationMessage21")} {empresa}{" "}
        {t("PrivacyPolitics.personalInfoUtilizationMessage22")}
        <h4>
          <strong>{t("PrivacyPolitics.sharingPersonalInfo")}</strong>
        </h4>
        <p>
          {t("PrivacyPolitics.sharingPersonalInfoMessage")} {empresa}{" "}
          {t("PrivacyPolitics.sharingPersonalInfoMessage2")} {empresa}{" "}
          {t("PrivacyPolitics.sharingPersonalInfoMessage3")}
          <br></br>
          {t("PrivacyPolitics.sharingPersonalInfoMessage4")} {empresa}{" "}
          {t("PrivacyPolitics.sharingPersonalInfoMessage5")}
          <br></br>
          {t("PrivacyPolitics.sharingPersonalInfoMessage6")} {empresa}{" "}
          {t("PrivacyPolitics.sharingPersonalInfoMessage7")} <br></br>
          {t("PrivacyPolitics.sharingPersonalInfoMessage8")} {empresa}{" "}
          {t("PrivacyPolitics.sharingPersonalInfoMessage9")} {empresa},{" "}
          {t("PrivacyPolitics.sharingPersonalInfoMessage10")} {empresa},{" "}
          {t("PrivacyPolitics.sharingPersonalInfoMessage11")} <br></br>
          {t("PrivacyPolitics.sharingPersonalInfoMessage12")} {empresa}{" "}
          {t("PrivacyPolitics.sharingPersonalInfoMessage13")}
          <br></br>
          {t("PrivacyPolitics.sharingPersonalInfoMessage14")} {empresa}{" "}
          {t("PrivacyPolitics.sharingPersonalInfoMessage15")} {empresa}{" "}
          {t("PrivacyPolitics.sharingPersonalInfoMessage16")}
          <br></br>
          {t("PrivacyPolitics.sharingPersonalInfoMessage17")} {empresa}{" "}
          {t("PrivacyPolitics.sharingPersonalInfoMessage18")} {empresa}{" "}
          {t("PrivacyPolitics.sharingPersonalInfoMessage19")}
          <br></br>
        </p>
        <h4>
          <strong>{t("PrivacyPolitics.storingPersonalInfo")}</strong>
        </h4>
        <p>
          {t("PrivacyPolitics.storingPersonalInfoMessage")} {empresa}{" "}
          {t("PrivacyPolitics.storingPersonalInfoMessage2")}
          <br></br>
          {t("PrivacyPolitics.storingPersonalInfoMessage3")} {empresa}{" "}
          {t("PrivacyPolitics.storingPersonalInfoMessage4")}
          <br></br>
          {t("PrivacyPolitics.storingPersonalInfoMessage5")} {empresa}{" "}
          {t("PrivacyPolitics.storingPersonalInfoMessage6")}
          <br></br>
          {t("PrivacyPolitics.storingPersonalInfoMessage7")} {empresa}{" "}
          {t("PrivacyPolitics.storingPersonalInfoMessage8")} {empresa},
          {t("PrivacyPolitics.storingPersonalInfoMessage9")}
          <br></br>
          {t("PrivacyPolitics.storingPersonalInfoMessage10")} {empresa}{" "}
          {t("PrivacyPolitics.storingPersonalInfoMessage11")} <br></br>
          {t("PrivacyPolitics.storingPersonalInfoMessage12")} {empresa}{" "}
          {t("PrivacyPolitics.storingPersonalInfoMessage13")}
          <br></br>
          {t("PrivacyPolitics.storingPersonalInfoMessage14")} {empresa}{" "}
          {t("PrivacyPolitics.storingPersonalInfoMessage15")}
          <br></br>
        </p>
        <h4>
          <strong>{t("PrivacyPolitics.internationalDataTransfer")}</strong>
        </h4>
        <p>
          {t("PrivacyPolitics.internationalDataTransferMessage")} {empresa}{" "}
          {t("PrivacyPolitics.internationalDataTransferMessage2")}
          <br></br>
          {t("PrivacyPolitics.internationalDataTransferMessage3")}
          <br></br>
        </p>
        <h4>
          <strong>{t("PrivacyPolitics.userRights")}</strong>
        </h4>
        <p>
          {t("PrivacyPolitics.userRightsMessage")} {empresa},{" "}
          {t("PrivacyPolitics.userRightsMessage2")} {email}:{" "}
          {t("PrivacyPolitics.userRightsMessage3")}
          <br></br>
          {t("PrivacyPolitics.userRightsMessage4")} {empresa}{" "}
          {t("PrivacyPolitics.userRightsMessage5")} {empresa}.{" "}
          {t("PrivacyPolitics.userRightsMessage6")} {empresa}{" "}
          {t("PrivacyPolitics.userRightsMessage7")}
          <br></br>
        </p>
        <h4>
          <strong>
            {t("PrivacyPolitics.platformUtilization")} {empresa}{" "}
            {t("PrivacyPolitics.platformUtilization2")}
          </strong>
        </h4>
        <p>
          {t("PrivacyPolitics.platformUtilizationMessage")} {empresa},{" "}
          {t("PrivacyPolitics.platformUtilizationMessage2")} {empresa}{" "}
          {t("PrivacyPolitics.platformUtilizationMessage3")} <br></br>
          {t("PrivacyPolitics.platformUtilizationMessage4")} {empresa}{" "}
          {t("PrivacyPolitics.platformUtilizationMessage5")}
          <br></br>
        </p>
        <h4>
          <strong>{t("PrivacyPolitics.cookiesAndOtherTechnologies")}</strong>
        </h4>
        <p>
          {t("PrivacyPolitics.cookiesAndOtherTechnologiesMessage")} {empresa}{" "}
          {t("PrivacyPolitics.cookiesAndOtherTechnologiesMessage2")} <br></br>
        </p>
        <h4>
          <strong>{t("PrivacyPolitics.generals")}</strong>
        </h4>
        <p>
          {t("PrivacyPolitics.generalsMessage")} {empresa}{" "}
          {t("PrivacyPolitics.generalsMessage2")} <br></br>
          {t("PrivacyPolitics.generalsMessage3")} {empresa}{" "}
          {t("PrivacyPolitics.generalsMessage4")} {empresa},{" "}
          {t("PrivacyPolitics.generalsMessage5")} {empresa}.<br></br>
          {t("PrivacyPolitics.generalsMessage6")}
          <br></br>
          {t("PrivacyPolitics.generalsMessage7")}
          <br></br>
          {t("PrivacyPolitics.generalsMessage8")} {empresa}{" "}
          {t("PrivacyPolitics.generalsMessage9")} {empresa},{" "}
          {t("PrivacyPolitics.generalsMessage10")} {email}.<br></br>
          {t("PrivacyPolitics.generalsMessage11")}
          <br></br>
        </p>
      </div>
    </div>
  );
}
