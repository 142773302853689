import React from "react";
import * as yup from "yup";
import TicketExhibitComponent from "../../../components/TicketExhibit";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form } from "react-bootstrap";
import { useAuth } from "../../../hooks/useAuth";
import { animateScroll as scroll } from "react-scroll";

export default function NominalTransferFirstScreen({
  handleScreenChange,
  handleData,
  ticketName,
  eventName,
  email,
  onSetEmail,
  confirmEmail,
  onSetConfirmEmail,
}) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const history = useHistory();

  const schema = yup.object({
    receiverEmail: yup
      .string()
      .required(t("ProfileForm.emailRequired"))
      .matches(/^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/, {
        message: t("Checkout.emailInvalid"),
      })
      .notOneOf([user.email], t("NominalTransfer.cantTransferToYourself")),
    confirmReceiverEmail: yup
      .string()
      .required(t("NominalTransfer.confirmEmailRequired"))
      .oneOf(
        [yup.ref("receiverEmail")],
        t("CommonExpressions.emailsDontMatch")
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const onSubmit = (data) => {
    handleData(data);
    handleScreenChange(2);
    scroll.scrollToTop();
  };
  return (
    <div className="d-flex flex-column" style={{ gap: "2rem" }}>
      <div className="title d-flex flex-column">
        <h3>{t("NominalTransfer.youAreTransferring")}</h3>

        <TicketExhibitComponent ticketName={ticketName} eventName={eventName} />
      </div>
      <div className="body d-flex flex-column">
        <h3 className="p-0">{t("NominalTransfer.transferTo")}</h3>
        <Form
          className="d-flex flex-column"
          style={{ gap: "24px" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Form.Group>
            <Form.Label>
              <span className="text-danger">*</span>{" "}
              {t("NominalTransfer.receiverEmail")}
            </Form.Label>
            <Form.Control
              id="receiver_email_input"
              type="email"
              placeholder={t("NominalTransfer.typeReceiverEmail")}
              {...register("receiverEmail")}
              onChange={({ target }) => {
                onSetEmail(target.value);
              }}
              value={email}
              isInvalid={!!errors?.receiverEmail}
              aria-describedby="ReceiverEmail"
            />
            <Form.Control.Feedback type="invalid">
              {errors?.receiverEmail?.message}
            </Form.Control.Feedback>
            {!errors?.receiverEmail?.message && (
              <Form.Text id="ReceiverEmail">
                {t("NominalTransfer.ticketRedeem")}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <span className="text-danger">*</span>
              {t("NominalTransfer.confirmReceiverEmail")}
            </Form.Label>
            <Form.Control
              id="confirm_receiver_email_input"
              type="email"
              placeholder={t("NominalTransfer.confirmReceiverEmail")}
              isInvalid={!!errors?.confirmReceiverEmail}
              {...register("confirmReceiverEmail")}
              onChange={({ target }) => {
                onSetConfirmEmail(target.value);
              }}
              value={confirmEmail}
            />
            <Form.Control.Feedback type="invalid">
              {errors?.confirmReceiverEmail?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="d-flex flex-column flex-lg-row-reverse button-container">
            <Button
              type="submit"
              id="submit"
              className="continue w-100"
              disabled={!email || Object.keys(errors).length > 0}
            >
              {t("NominalTransfer.continue")}
            </Button>

            <Button
              variant="secondary"
              id="cancel"
              type="button"
              className="cancel-transfer w-100"
              onClick={() => history.push("/meus-ingressos")}
            >
              {t("NominalTransfer.cancelTransfer")}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
