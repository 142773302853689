import axios from "axios";
import { useAuth } from "../hooks/useAuth";

export default class BaseApi {
  api;
  baseUrl;

  constructor(baseUrl, timeout = 40000) {
    this.baseUrl = baseUrl;

    this.api = axios.create({
      baseURL: baseUrl,
      timeout,
    });

    this.api.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
    this.api.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    this.api.interceptors.request.use(async (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    this.api.interceptors.response.use(
      (response) => response,
      async (error) => {
        const access_token = localStorage.getItem("token");

        if (error.response?.status === 401 && access_token) {
          const response = await this.#refreshToken(error);
          return response;
        }

        return Promise.reject(error);
      }
    );
  }

  get = (url, config = {}) => this.api.get(url, config);

  delete = (url, config = {}) => this.api.delete(url, config);

  post = (url, data = {}, config = {}) => this.api.post(url, data, config);

  put = (url, data = {}, config = {}) => this.api.put(url, data, config);

  patch = (url, data = {}, config = {}) => this.api.patch(url, data, config);

  #refreshToken = async () => {
    const { logout, updateToken } = useAuth();

    return new Promise((resolve, reject) => {
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        axios
          .post(this.baseUrl + "/auth/refresh", { headers })
          .then(async (res) => {
            updateToken(res.data.access_token);
            return resolve(res);
          })
          .catch((error) => {
            logout();
            return reject(error);
          });
      } catch (err) {
        return reject(err);
      }
    });
  };
}
