import { Spinner, Form, Button, Row, Col, Container } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import React, { useEffect, useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import ReactTooltip from "react-tooltip";
import * as yup from "yup";

import { ReactComponent as DiscountCouponIcon } from "../../assets/svg/discount-coupon.svg";
import { ReactComponent as DiscountInfoTooltipIcon } from "../../assets/svg/info.svg";
import { ReactComponent as Check } from "../../assets/svg/check.svg";
import { ReactComponent as Close } from "../../assets/svg/close.svg";

import "./styles.css";
import { useTranslation } from "react-i18next";

const DiscountCoupon = ({
  tickets,
  stewardCode,
  onPromoCode,
  onAppliedCode,
  onLoading,
  isLoading,
  isCodeApplied,
  discountCouponDescription,
  isDiscountInvalidForAll,
  isSeatsPage,
  seatsOnChangeCodePromo,
  seatsOnHandleApply,
  isInvalidDiscount,
  urlCoupon,
}) => {
  const hasAtLeastOneDiscount = tickets.some((item) => item.discount >= 0);
  const [reApplyDiscountCode, setReApplyDiscountCode] = useState(false);
  const isUserElegible = sessionStorage.getItem("isElegible");

  const isMobile = useWindowSize();
  const { t } = useTranslation();

  const schema = yup.object().shape({
    discountCodeInput: yup
      .string()
      .required(t("DiscountCoupon.invalid"))
      .matches(/^\S+$/, t("DiscountCoupon.invalid")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const discountCode = watch("discountCodeInput");

  const applyDiscount = async (data) => {
    setValue("discountCodeInput", data.discountCodeInput);

    await onPromoCode(data.discountCodeInput);

    onAppliedCode(data);

    setReApplyDiscountCode(false);
  };

  const applySeatsDiscount = async (data) => {
    setValue("discountCodeInput", data.discountCodeInput);

    seatsOnChangeCodePromo(data.discountCodeInput);

    await seatsOnHandleApply(data.discountCodeInput);

    onAppliedCode(data);

    setReApplyDiscountCode(false);
  };

  const onSubmit = async (data) => {
    onLoading(true);

    if (isSeatsPage) {
      try {
        await applySeatsDiscount(data);
      } catch (error) {
        setError("discountCodeInput", {
          message: t("DiscountCoupon.unavailable"),
        });
      }
    } else {
      await applyDiscount(data);
    }

    setTimeout(() => {
      onLoading(false);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    if (
      isDiscountInvalidForAll ||
      isInvalidDiscount ||
      !hasAtLeastOneDiscount
    ) {
      setError("discountCodeInput", {
        message: t("DiscountCoupon.unavailable"),
      });
    } else if (isCodeApplied && isUserElegible === "true") {
      clearErrors("discountCodeInput");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDiscountInvalidForAll,
    isCodeApplied,
    isInvalidDiscount,
    hasAtLeastOneDiscount,
  ]);

  useEffect(() => {
    if (stewardCode && isCodeApplied) {
      setValue("discountCodeInput", stewardCode);
      onAppliedCode(stewardCode);
    }
    if (urlCoupon) {
      setValue("discountCodeInput", urlCoupon);
      onAppliedCode(urlCoupon);
      if (isUserElegible === "true") {
        onAppliedCode(urlCoupon);
      }
    }
    if (discountCode) {
      localStorage.setItem("code", discountCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stewardCode, urlCoupon, discountCode]);

  return (
    <Container className="discount-coupon d-flex flex-column">
      <Row
        className={`d-flex justify-content-center gap-3 ${
          isSeatsPage && !isMobile ? "flex-nowrap" : ""
        }`}
      >
        <Col
          className={`discount-coupon-title d-flex p-0 align-items-center justify-content-center`}
        >
          <DiscountCouponIcon width={18} height={18} />
          <span>{t("DiscountCoupon.coupon")}</span>
          {discountCouponDescription && (
            <span data-tip={discountCouponDescription}>
              <ReactTooltip
                className="description-tooltip"
                html={true}
                placement="top"
                effect="solid"
              />
              <DiscountInfoTooltipIcon width={18} height={18} />
            </span>
          )}
        </Col>

        <div
          className={`discount-coupon-body d-flex justify-content-center ${
            !isSeatsPage ? "w-100" : ""
          }`}
        >
          <Col xs={8} className="p-0 height-5">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group
                className={`discount-input-container d-flex align-items-center ${
                  errors.discountCodeInput?.message
                    ? "error-input"
                    : reApplyDiscountCode
                    ? "reaply-input"
                    : ""
                }`}
              >
                <Form.Control
                  type="text"
                  id="discount-code-input"
                  className="discount-code-input"
                  placeholder={t("DiscountCoupon.insert")}
                  disabled={isLoading || (stewardCode && isCodeApplied)}
                  {...register("discountCodeInput")}
                  onFocus={() => {
                    if (!errors.discountCodeInput && isCodeApplied) {
                      setReApplyDiscountCode(true);
                    }
                  }}
                  onBlur={() => {
                    if (reApplyDiscountCode) {
                      setReApplyDiscountCode(false);
                    }
                  }}
                />
                <div className="mr-1">
                  {isLoading ? (
                    <Spinner
                      className="d-flex m-0"
                      animation="border"
                      variant="primary"
                      size="sm"
                    />
                  ) : isCodeApplied ? (
                    errors.discountCodeInput?.message ===
                    t("DiscountCoupon.unavailable") ? (
                      <Close />
                    ) : (
                      <Check />
                    )
                  ) : null}
                </div>
              </Form.Group>
            </Form>
          </Col>

          <Col xs={3} className="height-5 p-0 w-100">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Button
                type="submit"
                className="w-100 align-items-center justify-content-center text-center discount-coupon-button"
                disabled={
                  isLoading ||
                  (stewardCode && typeof isCodeApplied === "string")
                }
              >
                {t("CommonExpressions.apply")}
              </Button>
            </Form>
          </Col>
        </div>
      </Row>

      <Row>
        <Col xs={12} className="px-3 pt-1 text-left">
          <span
            className={`${
              errors.discountCodeInput
                ? "discount-error-message"
                : "discount-information-message"
            }`}
          >
            {errors.discountCodeInput?.message ||
              (reApplyDiscountCode && t("DiscountCoupon.applyNew"))}
          </span>
        </Col>
      </Row>
    </Container>
  );
};

export default DiscountCoupon;
