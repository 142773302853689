import { atom } from "recoil";

export const userLocation = atom({
  key: "userData",
  default: {
    loadingCoordinates: false,
    isAcceptedLocation: document.cookie
      .split("; ")
      .find((cookie) => cookie.includes("locationPermission=granted"))
      ? true
      : false,
    userData: JSON.parse(localStorage.getItem("userData")) || null,
  },
});
