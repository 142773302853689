import React, { Fragment, useCallback } from "react";
import { Modal } from "react-bootstrap";
import close_x from "../../assets/svg/close_x.svg";
import useWindowSize from "../../hooks/useWindowSize";
import "./styles.css";
import { useTranslation } from "react-i18next";

export function SoldOutModal({
  unavailableTickets,
  moreThanOnTickets,
  show,
  onClose,
  onBuy,
}) {
  const isMobile = useWindowSize();
  const { t } = useTranslation();
  const closeModal = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <Fragment>
      <Modal
        className="location-modal sold-out-modal"
        show={show}
        onHide={closeModal}
      >
        <Modal.Header>
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center location-title">
              <Modal.Title
                style={{ color: "#12263F" }}
                className="new-modal-title"
              >
                {t("SoldOutModal.ticket")}
              </Modal.Title>
              <button
                onClick={closeModal}
                type="button"
                className="close"
                aria-label="Close"
              >
                <img
                  src={close_x}
                  alt="close icon"
                  className="x-close"
                  aria-hidden="true"
                ></img>
              </button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body
          className="modal-flow overflow-auto p-0 d-flex flex-column justify-content-between"
          style={{ padding: 0, maxHeight: isMobile ? "auto" : 300 }}
        >
          <div className="sold-out-container">
            {unavailableTickets.length > 0
              ? `${t("SoldOutModal.ticketsPlural")} ${unavailableTickets} ${t(
                  "SoldOutModal.soldOutPlural"
                )}`
              : `${t("SoldOutModal.ticketSingular")} ${unavailableTickets} ${t(
                  "SoldOutModal.soldOutSingular"
                )}`}
          </div>
          {moreThanOnTickets && <span>{t("SoldOutModal.continue")}</span>}
          <ul>
            {moreThanOnTickets && (
              <li>
                <button className={"secondary"} onClick={onBuy}>
                  {t("Event.goToPayment")}
                </button>
              </li>
            )}
            <li className="mt-3">
              <button
                className={moreThanOnTickets ? "primary" : "secondary"}
                onClick={closeModal}
              >
                {t("SoldOutModal.chooseOtherTicket")}
              </button>
            </li>
          </ul>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}
