import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { ReactComponent as NominalTransferSuccessIcon } from "../../assets/svg/transfer-success-icon.svg";

import "./styles.css";
export default function NominalTransferSuccessModal({
  show,
  onClose,
  recipientsMail,
  eventName,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    onClose(false);
    history.push("/meus-ingressos");
  };

  return (
    <Modal show={show} size="lg" backdrop="static" keyboard={false}>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-column text-center success-modal">
        <div>
          <NominalTransferSuccessIcon width={169.99} height={100} />
        </div>

        <div>
          <h3 className="success-modal-title">
            {t("NominalTransfer.success")}
          </h3>
        </div>

        <div className="success-modal-body d-flex flex-column">
          <p className="m-0">
            {t("NominalTransfer.successMessage1")}{" "}
            <strong>{recipientsMail}</strong>{" "}
            {t("NominalTransfer.successMessage2")} <strong>{eventName}.</strong>
          </p>
          <p className="m-0">{t("NominalTransfer.mailSendMessage")}</p>
        </div>

        <Button
          onClick={handleClick}
          className="success-modal-button w-100 px-4 py-3"
        >
          {t("NominalTransfer.close")}
        </Button>
      </Modal.Body>
    </Modal>
  );
}
