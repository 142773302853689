import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { pushGAEvent } from "../../Helpers/tagManager";
import Beer from "../../assets/svg/Beer.svg";
import Ticket from "../../assets/svg/NewTicket.svg";
import Party from "../../assets/svg/party.svg";
import Zig from "../../assets/svg/zig.svg";
import { setCookie } from "../../services/constants";
import "./styles.css";

export default function DownloadPopup({ os, app, isOpen, setIsOpen }) {
  const { t } = useTranslation();

  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = isOpen
      ? "hidden"
      : "auto";
  }, [isOpen]);

  const pushEventToGA4 = (option_call) => {
    pushGAEvent("clk_call_download_app", {
      platform: app ? "Android" : os,
      download_call_option: option_call,
    });
  };

  const handleRedirect = () => {
    pushEventToGA4("yes_install_app");
    setCookie("downloadPopup", { userAction: "yes_install_app" }, 5184000);
    setIsOpen(false);
    window.location.href = "https://zigpay.page.link/download";
  };

  const handleRemind = () => {
    setCookie(
      "downloadPopup",
      { userAction: "no_install_app", date: new Date() },
      5184000
    );
    setIsOpen(false);
    pushEventToGA4("no_install_app");
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <>
      <div className="popup-modal-overlay"></div>
      <div className="popup-modal">
        <div className="popup-modal-content">
          <CentralizedIconContainer>
            <Image src={Zig} alt="Zig Logo" className="zig-icon" />
          </CentralizedIconContainer>

          <CentralizedQuantityContainer>
            <p className="download-quantity-text">
              {t("DownloadPopup.quantity")}
            </p>
          </CentralizedQuantityContainer>

          <CentralizedTitleContainer>
            <h5 className="download-title">{t("DownloadPopup.title")}</h5>
          </CentralizedTitleContainer>

          <ArgumentsContainer>
            <ArgumentsItensContainer>
              <Image src={Ticket} alt="Ticket Icon" className="pr-1" />
              <p className="arguments-text">
                {t("DownloadPopup.argumentsTicket")}
              </p>
            </ArgumentsItensContainer>

            <ArgumentsItensContainer>
              <Image src={Beer} alt="Beer Icon" className="pr-1" />
              <p className="arguments-text">
                {t("DownloadPopup.argumentsBeer")}
              </p>
            </ArgumentsItensContainer>

            <ArgumentsItensContainer>
              <Image src={Party} alt="Party Icon" className="pr-1" />
              <p className="arguments-text">
                {t("DownloadPopup.argumentsParty")}
              </p>
            </ArgumentsItensContainer>
          </ArgumentsContainer>

          <ButtonsContainer>
            <DownloadButton onClick={() => handleRedirect()}>
              {t("DownloadPopup.downloadButton")}
            </DownloadButton>
            <CancelButton onClick={() => handleRemind()}>
              {t("DownloadPopup.cancelButton")}
            </CancelButton>
          </ButtonsContainer>
        </div>
      </div>
    </>
  );
}

const CentralizedIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  bottom: 3.65rem;
`;

const CentralizedQuantityContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  bottom: 3.5rem;
`;

const CentralizedTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  bottom: 2rem;
`;

const ArgumentsItensContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: 1rem;
`;

const ArgumentsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
`;

const DownloadButton = styled.button`
  width: 100%;
  min-height: 2.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  font-weight: 700;
  background-color: #825bf4;
  color: #fff;
  border: none;
  &:hover {
    background-color: #002d8c;
  }
  &:active {
    background-color: #00145f;
  }
`;

const CancelButton = styled.button`
  width: 100%;
  min-height: 2.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-weight: 700;
  background-color: transparent;
  color: #171719;
  border: none;
  &:hover {
    background-color: #eae9ed;
  }
  &:active {
    background-color: #d3d2d9;
  }
`;
