import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./index.css";
import { useTranslation } from "react-i18next";

export default function AcceptTermsView({ checked, onChange }) {
  const [accepted, setAccepted] = useState(checked);
  const { t } = useTranslation();

  const handleChange = () => {
    const newValue = !accepted;

    setAccepted(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    setAccepted(checked);
  }, [checked]);

  return (
    <div className="terms-approval" onClick={handleChange}>
      <Row noGutters>
        <Col xs="2" md="1" className="d-flex justify-content-center">
          <div className={`terms-checkbox ${accepted ? "accepted" : ""}`}>
            {accepted && (
              <span className="terms-checkmark">
                <div className="terms-checkmark-stem"></div>
                <div className="terms-checkmark-kick"></div>
              </span>
            )}
          </div>
        </Col>
        <Col xs="10" md="11" className="pr-3">
          <span className="terms-approval-title">
            {t("AcceptTermsView.procceed")}{" "}
            <Link target="_blank" to="/termos">
              {t("AcceptTermsView.terms")}
            </Link>
            {t("AcceptTermsView.relativeTerms")}
          </span>
          <span className="terms-approval-subtitle">
            {t("AcceptTermsView.verification")}
          </span>
        </Col>
      </Row>
    </div>
  );
}
