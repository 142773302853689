import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

NewButton.propTypes = {
  className: PropTypes.string,
  content: PropTypes.node.isRequired,
};

export default function NewButton({ className, content, ...restProps }) {
  return (
    <button className={`button-contained ${className}`} {...restProps}>
      {content}
    </button>
  );
}
