import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import MaskHelper from "../../Helpers/mask";

export const InstallmentsView = ({
  watch,
  errors,
  register,
  translate,
  installments,
}) => {
  return (
    <Row>
      <Col className="mb-2">
        <Form.Group>
          <Form.Label htmlFor="installments">
            <span className="text-danger">*</span>
            {translate("Checkout.installments")} {installments.length}x)
          </Form.Label>
          <Form.Control
            id="installments"
            as="select"
            className="form-control"
            {...register("payment.installments")}
          >
            {installments.map((installment, index) => (
              <option key={index} value={index + 1}>
                {index + 1}x de{" "}
                {MaskHelper.numberToLocaleCurrency(installment.installment)} ={" "}
                {MaskHelper.numberToLocaleCurrency(installment.total)}
              </option>
            ))}
          </Form.Control>
          {errors.payment?.installments && (
            <Form.Text className="error-message">
              {errors.payment?.installments?.message}
            </Form.Text>
          )}
        </Form.Group>
      </Col>
    </Row>
  );
};
