import React, { useState, useEffect, useRef, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import eua from "../../assets/svg/eua.svg";
import brazil from "../../assets/svg/brazil.svg";
import spain from "../../assets/svg/spain.svg";
import "./styles.css";
import DateHelper from "../../Helpers/date";
import NewApi from "../../services/new-api";
import { useZendesk } from "../../hooks/useZendesk";

export function ChangeLanguageButton() {
  const isMobile = window.innerWidth <= 991;
  const dropdownRef = useRef(null);
  const api = new NewApi();
  const { i18n } = useTranslation();
  const { changeLocale } = useZendesk();

  const { user, isLogged } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  let initialLanguage = localStorage.getItem("i18nextLng");
  const languages = [
    { code: "pt-BR", label: "Português - BR", image: brazil },
    { code: "es-ES", label: "Español - ES", image: spain },
    { code: "en-US", label: "English - US", image: eua },
  ];

  useEffect(() => {
    if (isLogged && user?.chosen_language) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      initialLanguage = user.chosen_language;
    }

    handleLanguageChange(initialLanguage);

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLanguageChange = async (lang) => {
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang);
    DateHelper.setLocale();
    setIsOpen(false);

    changeLocale(lang);

    if (isLogged) {
      await api.patch("users/language", {
        chosen_language: lang,
        birthdate: user.birthdate,
      });
    }
  };

  const handleModalClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const selectedLanguageInfo =
    languages.find((l) => l.code === selectedLanguage) || {};
  const flag = selectedLanguageInfo?.image;
  const label = selectedLanguageInfo?.label;

  return (
    <div className="language-selector">
      {isMobile ? (
        <>
          <button
            type="button"
            className="language-button"
            onClick={() => setIsOpen(!isOpen)}
          >
            <img src={flag} alt="Language flag" /> {label}
          </button>
          {isOpen && (
            <Modal
              show={isOpen}
              onHide={handleModalClose}
              className="language-modal"
            >
              <Modal.Body className="language-body">
                {languages.map((language) => (
                  <div
                    key={language.code}
                    className="language-item"
                    onClick={() => {
                      handleLanguageChange(language.code);
                      handleModalClose();
                    }}
                  >
                    <img src={language.image} alt={language.label + " flag"} />
                    {language.label}
                  </div>
                ))}
              </Modal.Body>
            </Modal>
          )}
        </>
      ) : (
        <div className="dropdown" ref={dropdownRef}>
          <button
            className="dropdown-toggle"
            onClick={() => setIsOpen(!isOpen)}
          >
            <img src={flag} alt="Language flag" />
            {isMobile ? label : selectedLanguage.slice(3, 5).toUpperCase()}
          </button>
          {isOpen && (
            <div className="dropdown-menu">
              {languages.map((language) => (
                <div
                  key={language.code}
                  className="dropdown-item"
                  onClick={() => handleLanguageChange(language.code)}
                >
                  <img src={language.image} alt={language.label + " flag"} />
                  {language.label}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
