import React, { useState } from "react";

import warning from "../../assets/svg/warning.svg";
import success from "../../assets/svg/success.svg";
import arrow from "../../assets/svg/arrow.svg";

export const DiscountDescriptionView = ({
  discountCoupon,
  isUserElegibleForDiscount,
  integrationCouponDescription,
  translate,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={`discount-description ${
        isUserElegibleForDiscount ? "success" : "error"
      }`}
      onClick={handleToggleExpand}
    >
      <div className="discount-title">
        <img
          src={isUserElegibleForDiscount ? success : warning}
          alt="alert box icon"
          id="alert-box-icon"
        />
        <p>
          <strong>
            {isUserElegibleForDiscount ? (
              <>
                {translate("Checkout.appliedDiscount")} {discountCoupon}.
              </>
            ) : (
              <>
                {translate("Checkout.coupon")} {discountCoupon}{" "}
                {translate("Checkout.notEligibleDiscount")}
              </>
            )}
          </strong>
        </p>
        {!isUserElegibleForDiscount && integrationCouponDescription && (
          <img
            src={arrow}
            alt="arrow icon"
            id="arrow-icon"
            className={isExpanded ? "rotated-arrow" : ""}
          />
        )}
      </div>
      <div>
        {!isUserElegibleForDiscount &&
          isExpanded &&
          integrationCouponDescription && (
            <p id="coupon-description">{integrationCouponDescription}</p>
          )}
      </div>
    </div>
  );
};
