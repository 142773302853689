import React, { useState, useEffect } from "react";
import { Textarea, Textbox } from "react-inputs-validation";
import swal from "sweetalert2";
import SelectSearch from "react-select-search";
import { useHistory } from "react-router-dom";
import NewApi from "../../../services/new-api";
import Loader from "../../../components/Loader";

import "./index.css";
import sendToLogger from "../../../Helpers/errorLogger";
import { useTranslation } from "react-i18next";

const api = new NewApi();

export default function Index() {
  const nomeEmpresa = process.env.REACT_APP_NAME_EMPRESA;
  const { t } = useTranslation();
  const [typeUser, setTypeUser] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [event, setEvent] = useState("");
  const [listEvent, setListEvent] = useState([]);
  const [option, setOption] = useState(t("FaleForm.eventQuestions"));
  const [code, setCode] = useState("");
  const [msg, setMsg] = useState("");
  const [hasNameError, setNameError] = useState(true);
  const [hasEmailError, setEmailError] = useState(true);
  const [hasMsgError, setMsgError] = useState(true);
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [erroEvent, setErroEvent] = useState(false);

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    setTypeUser(localStorage.getItem("typeQuestion"));
    listarEventos();
  }, []);

  function toggleValidating(validate) {
    setValidate(validate);
  }

  const listarEventos = (searchQuery) => {
    const query = {};

    if (searchQuery) {
      query.by_name = searchQuery;
    }

    api.get("contact-events", { params: query }).then((res) => {
      setListEvent(
        res.data.data.map((item) => ({ value: item.name, name: item.name }))
      );
    });
  };

  function verifyForm(e) {
    e.preventDefault();
    toggleValidating(true);
    if (event === "") {
      setErroEvent(true);
    } else {
      setErroEvent(false);
    }
    if (
      !hasNameError &&
      !hasEmailError &&
      !hasMsgError &&
      event !== "" &&
      option
    ) {
      sendMessage();
    }
  }

  function sendMessage() {
    window.scrollTo(0, 0);
    setLoading(true);

    const form = {
      user_type: typeUser,
      name,
      email,
      event,
      option,
      code,
      message: msg,
    };

    api
      .post("contact", form)
      .then(() => {
        successMessage(`${t("FaleForm.emailSent")} ${nomeEmpresa}`);

        history.push("/");
      })
      .catch((error) => {
        sendToLogger(error);
        errorMessage(t("SeatsChart.passwordUpdateError"));
      })
      .finally(() => setLoading(false));
  }

  function errorMessage(message) {
    swal.fire({
      icon: "error",
      title: t("FaleForm.oops"),
      text: message,
    });
  }

  function successMessage(message) {
    swal.fire({
      icon: "success",
      title: t("CommonExpressions.success"),
      text: message,
    });
  }

  return (
    <>
      {loading ? (
        <>
          <div className="loading-container"></div>
          <Loader></Loader>
        </>
      ) : (
        <div className="container" id="container-question">
          <div className="row">
            <div className="col-md-12">
              <div className="col-md-12">
                <h3>{t("FaleForm.requestForm")}</h3>
                <hr />
              </div>
              <div className="card shadow pt-1 pl-3 pb-4 pr-3">
                <h4 style={{ paddingTop: "30px" }}>
                  1. {t("FaleForm.personalInfo")}
                </h4>
                <hr />
                <div className="mt-3">
                  <label htmlFor="nome" className="strong">
                    {t("CommonExpressions.name")}:
                  </label>
                  <Textbox
                    attributesInput={{
                      id: "nome",
                      name: "first_name",
                      maxLength: 255,
                      type: "text",
                    }}
                    value={name}
                    disabled={false}
                    validate={validate}
                    classNameInput="form-control"
                    onChange={(name, e) => {
                      setName(name);
                    }}
                    onBlur={(e) => {}}
                    validationOption={{
                      check: true,
                      required: true,
                      type: "string",
                      msgOnError: t("CommonExpressions.nameRequired"),
                    }}
                    validationCallback={(res) => {
                      setNameError(res);
                      setValidate(false);
                    }}
                  />
                </div>
                <div className="mt-3">
                  <label htmlFor="" className="strong">
                    {t("FaleForm.email")}
                  </label>
                  <Textbox
                    attributesInput={{
                      id: "email",
                      name: "email",
                      maxLength: 255,
                      type: "email",
                    }}
                    value={email}
                    disabled={false}
                    validate={validate}
                    classNameInput="form-control"
                    onChange={(email, e) => {
                      setEmail(email);
                    }}
                    onBlur={(e) => {}}
                    validationOption={{
                      check: true,
                      required: true,
                      type: "string",
                      msgOnError: t("ProfileForm.emailRequired"),
                    }}
                    validationCallback={(res) => setEmailError(res)}
                  />
                  {typeUser === "user" && (
                    <span className="mt-2 form-span">
                      <strong>{t("Event.attention")}</strong>{" "}
                      {t("FaleForm.securityNotice")}
                    </span>
                  )}
                </div>
              </div>
              <div className="card shadow pt-1 pl-3 pb-4 pr-3">
                <h4 style={{ paddingTop: "30px" }}>
                  2. {t("FaleForm.additionalInfo")}
                </h4>
                <hr />
                <div className="mt-3">
                  <label htmlFor="" className="strong">
                    {t("FaleForm.eventName")}
                  </label>
                  <SelectSearch
                    id="ssForm"
                    options={listEvent}
                    value={event}
                    onChange={(value) => setEvent(value)}
                    search={true}
                    placeholder={t("FaleForm.selectEvent")}
                    getOptions={(query) => listarEventos(query)}
                  />
                  {erroEvent && (
                    <p style={{ marginTop: 0, color: "red" }}>
                      {t("FaleForm.eventRequired")}
                    </p>
                  )}
                </div>

                <div className="mt-3">
                  <label htmlFor="" className="strong">
                    {t("FaleForm.contactReason")}
                  </label>
                  <select
                    className="form-control"
                    onChange={(e) => setOption(e.target.value)}
                  >
                    <option value={t("FaleForm.eventQuestions")} defaultValue>
                      {t("FaleForm.eventQuestions")}
                    </option>
                    <option value={t("FaleForm.changeTicketName")}>
                      {t("FaleForm.changeTicketName")}
                    </option>
                    <option value={t("FaleForm.ticketCancellation")}>
                      {t("FaleForm.ticketCancellation")}
                    </option>
                    <option value={t("FaleForm.purchaseProblems")}>
                      {t("FaleForm.purchaseProblems")}
                    </option>
                    <option value={t("FaleForm.ticketIssues")}>
                      {t("FaleForm.ticketIssues")}
                    </option>
                    {typeUser !== "user" && (
                      <option value={t("FaleForm.platformSales")}>
                        {t("FaleForm.platformSales")}
                      </option>
                    )}
                  </select>
                </div>

                {(option === t("FaleForm.ticketCancellation") ||
                  option === t("FaleForm.changeTicketName")) && (
                  <div className="mt-3 fadeIn">
                    <label htmlFor="" className="strong">
                      {t("FaleForm.purchaseCode")}
                    </label>
                    <input
                      type="text"
                      value={code}
                      className="form-control"
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div>
                )}

                <div className="mt-3">
                  <label htmlFor="" className="strong">
                    {t("FaleForm.message")}
                  </label>
                  <Textarea
                    attributesInput={{
                      id: "msg",
                      name: "mensagem",
                      maxLength: 300,
                      type: "msg",
                    }}
                    value={msg}
                    disabled={false}
                    validate={validate}
                    classNameInput="form-control"
                    onChange={(msg, e) => {
                      setMsg(msg);
                    }}
                    onBlur={(e) => {}}
                    validationOption={{
                      check: true,
                      required: true,
                      type: "string",
                      msgOnError: t("FaleForm.messageRequired"),
                    }}
                    validationCallback={(res) => setMsgError(res)}
                  />
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <button className="btn btn-primary cem" onClick={verifyForm}>
                  {t("FaleForm.send")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
