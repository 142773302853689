import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import "./styles.css";

export function UserOptionsButton({ handleLogout }) {
  const dropdownRef = useRef(null);
  const { t } = useTranslation();
  const { user, isLogged } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const menuItems = [
    {
      key: "MyOrders.myOrders",
      text: t("MyOrders.myOrders"),
      action: "myOrders",
    },
    {
      key: "Header.myTickets",
      text: t("Header.myTickets"),
      action: "myTickets",
    },
    {
      key: "MyEvents.myEvents",
      text: t("MyEvents.myEvents"),
      action: "myEvents",
    },
    {
      key: "Header.profileData",
      text: t("Header.profileData"),
      action: "profileData",
    },
    { key: "Header.logout", text: t("Header.logout"), action: "logout" },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAction = (action) => {
    if (action === "logout") {
      handleLogout();
      return;
    }

    const redirectUrls = {
      myOrders: "/meus-pedidos",
      myTickets: "/meus-ingressos",
      myEvents: "/meus-eventos",
      profileData: "/perfil",
    };

    const redirectUrl = redirectUrls[action];
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  return (
    <div className="options-selector">
      <div className="dropdown" ref={dropdownRef}>
        <button className="dropdown-toggle" onClick={() => setIsOpen(!isOpen)}>
          {isLogged
            ? `${t("CommonExpressions.welcome")} ${user?.first_name}`
            : t("Header.enter")}
        </button>
        {isOpen && (
          <div className="dropdown-menu">
            {menuItems.map((item) => (
              <div
                key={item.key}
                className="dropdown-item"
                onClick={() => handleAction(item.action)}
              >
                {item.text}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
