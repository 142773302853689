import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { usePreferences } from "../../hooks/usePreferences";

import AuthenticationFlow from "../Auth";
import "./styles.css";
import { useAuth } from "../../hooks/useAuth";
import { isInZigApp } from "../../services/utils";

FavoriteButton.propTypes = {
  style: PropTypes.object,
  eventId: PropTypes.number.isRequired,
  eventName: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

export default function FavoriteButton({
  eventId,
  eventName,
  style,
  onFocus,
  onBlur,
}) {
  const { isLogged } = useAuth();
  const { favoriteEvents, toggleFavoriteEvent } = usePreferences();

  const [isAnimating, setIsAnimating] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [isFavorited, setIsFavorited] = useState(
    favoriteEvents.includes(eventId)
  );

  useEffect(() => {
    setIsFavorited(favoriteEvents.includes(eventId));
  }, [favoriteEvents, eventId]);

  const handleFavoriteEvent = async () => {
    setIsDisabled(true);
    try {
      await toggleFavoriteEvent(eventId, eventName);
    } finally {
      setTimeout(() => setIsDisabled(false), 500);
    }
  };

  const handleClick = async (e) => {
    e.stopPropagation();

    if (onFocus) {
      onFocus();
    }

    setIsAnimating(true);

    if (!isLogged) {
      setOpenLoginModal(true);
    } else {
      try {
        await handleFavoriteEvent();
      } finally {
        setIsAnimating(false);
      }
    }
    setIsDisabled(false);
  };

  const handleCloseAuth = () => {
    setOpenLoginModal(false);

    if (onBlur) {
      onBlur();
    }
  };

  return (
    !isInZigApp() && (
      <>
        <button
          onClick={handleClick}
          disabled={isDisabled}
          className={
            "button-favorite d-flex justify-content-center align-items-center"
          }
          style={style}
        >
          <i
            className={`${isFavorited ? "fas fa-heart" : "far fa-heart"} ${
              isAnimating ? "heartBeatAnimation" : ""
            }`}
          />
        </button>

        <AuthenticationFlow
          show={openLoginModal}
          nextToUrl={window.location.href}
          onClose={handleCloseAuth}
          onLoginCallback={handleFavoriteEvent}
        />
      </>
    )
  );
}
