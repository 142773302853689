import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as UnplannedIcon } from "../../assets/svg/unplanned.svg";

import "./styles.css";

export default function SomethingUnplannedHappened({ message, title }) {
  const { t } = useTranslation();

  return (
    <div className="unplanned-container d-flex flex-column">
      <div className="d-flex align-items-center justify-content-center">
        <UnplannedIcon width={80} height={80} />
      </div>

      <div className="unplanned-text-container d-flex flex-column">
        <div className="unplanned-title text-center">
          <h3>{title || t("UnplannedError.somethingUnplannedHappened")}</h3>
        </div>

        <div className="unplanned-body text-center">
          {message || t("UnplannedError.somethingUnplannedHappenedMessage")}
        </div>
      </div>
    </div>
  );
}

export const SomethingUnplannedHappenedModal = ({
  show,
  onClose,
  to,
  message,
  title,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    onClose(false);
    if (to) {
      history.push(to);
    }
  };

  return (
    <Modal show={show}>
      <Modal.Body>
        <div className="d-flex flex-column unplanned-modal">
          <SomethingUnplannedHappened message={message} title={title} />

          <Button
            onClick={handleClick}
            className="success-modal-button w-100 px-4 py-3"
          >
            {t("NominalTransfer.close")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
