import { useRecoilState, useRecoilValue } from "recoil";
import { authStore } from "../atoms/auth";
import { favoritedEventsStore } from "../atoms/preferencesStore";
import NewApi from "../services/new-api";
import { pushGAEvent } from "../Helpers/tagManager";
import MoengageService from "../services/moengage";

export const usePreferences = () => {
  const api = new NewApi();

  const [favoriteEvents, setFavoriteEvents] =
    useRecoilState(favoritedEventsStore);

  const { user } = useRecoilValue(authStore);

  const toggleFavoriteEvent = async (eventId, eventName) => {
    const isFavorited = favoriteEvents.includes(eventId);

    if (isFavorited) {
      await api.delete(`events/${eventId}/unfavorite`);

      pushGAEvent("remove_from_wishlist", {
        items: [{ item_id: eventId, item_name: eventName }],
      });

      if (user) {
        await MoengageService.createEvent({
          type: "event",
          customer_id: user.id,
          actions: [
            {
              action: "Unfavorite Event",
              attributes: {
                ...user,
              },
              platform: "web",
            },
          ],
        });
      }

      setFavoriteEvents(favoriteEvents.filter((id) => id !== eventId));

      localStorage.setItem(
        "user",
        JSON.stringify({
          ...user,
          favorite_events: user.favorite_events.filter(
            (favoriteEvent) => favoriteEvent.event_id !== eventId
          ),
        })
      );
    } else {
      await api.post(`events/${eventId}/favorite`);

      pushGAEvent("add_to_wishlist", {
        items: [{ item_id: eventId, item_name: eventName }],
      });

      if (user) {
        await MoengageService.createEvent({
          type: "event",
          customer_id: user.id,
          actions: [
            {
              action: "Favorite Event",
              attributes: {
                ...user,
              },
              platform: "web",
            },
          ],
        });
      }

      setFavoriteEvents([...favoriteEvents, eventId]);

      localStorage.setItem(
        "user",
        JSON.stringify({
          ...user,
          favorite_events: [...user.favorite_events, { event_id: eventId }],
        })
      );
    }
  };

  return {
    favoriteEvents,
    setFavoriteEvents,
    toggleFavoriteEvent,
  };
};
