import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import ticket from "../../../assets/img/ticket.png";
import workflow from "../../../assets/img/workflow.png";
import send from "../../../assets/img/send.png";
import question from "../../../assets/img/question.png";
import skip from "../../../assets/img/skip.png";
import NewApi from "../../../services/new-api";

import "./index.css";
import { useTranslation } from "react-i18next";

export default function Index({ history }) {
  const [allFaqs, setAllFaqs] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.removeItem("typeQuestion");
    window.scrollTo(0, 0);

    new NewApi().get("faqs", { params: { page: 1, per_page: 4 } }).then(
      (res) => setAllFaqs(res.data.data),
      () => {}
    );
  }, []);

  function redirect(params) {
    localStorage.setItem("typeQuestion", params);
    history.push("/fale-form");
  }

  function redirectSingleFac(id) {
    history.push("/faqs/" + id);
  }

  function pageFaqs(tipe) {
    !tipe
      ? localStorage.removeItem("TIPE_FAQ")
      : localStorage.setItem("TIPE_FAQ", tipe);
    history.push("/faqs");
  }

  return (
    <>
      <div id="help-header">
        <h3 className="text-center">{t("FaleMain.helpCenter")}</h3>
      </div>
      <div id="helper-body">
        <div className="container">
          <div className="row d-flex justify-content-cneter">
            <div className="col-md-5 col-lg-5 col-xl-5 offset-md-1 blocotes b-1">
              <div className="blocote-icon">
                <img src={ticket} alt="" />
              </div>
              <div className="blocote-content">
                <h5 className="text-center">
                  {t("FaleMain.buyerParticipant")}
                </h5>
                <p className="text-center">
                  {t("FaleMain.ticketInquiry")}
                  <Link
                    onClick={() => pageFaqs("Ingresso-Cliente")}
                    style={{ fontWeight: "bold", marginLeft: 5 }}
                  >
                    {t("FaleMain.frequentQuestions")}
                  </Link>
                </p>

                <div
                  className="f-question"
                  onClick={() => pageFaqs("Ingresso-Cliente")}
                >
                  <div className="row" style={{ cursor: "pointer" }}>
                    <div className="col-md-2 col-lg-2 col-xl-2 text-center">
                      <img src={question} alt="" />
                    </div>
                    <div className="col-md-8 col-lg-8 col-xl-8 text-center">
                      <p>
                        <b>{t("FAQs.frequentQuestions")}</b>
                        <br />
                        <small>{t("Footer.faq")}</small>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="f-question" style={{ marginTop: 20 }}>
                  <div className="row" style={{ cursor: "pointer" }}>
                    <div className="col-md-2 col-lg-2 col-xl-2 text-center">
                      <img src={send} alt="" />
                    </div>
                    <div
                      className="col-md-8 col-lg-8 col-xl-8 text-center"
                      onClick={() => redirect("user")}
                    >
                      <p>
                        <b>{t("FaleMain.contactForm")}</b>
                        <br />
                        <small>{t("FaleMain.return")}</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 col-xl-5 blocotes" id="b2">
              <div className="blocote-icon">
                <img src={workflow} alt="" />
              </div>
              <div className="blocote-content">
                <h5 className="text-center">{t("FaleMain.producersEvents")}</h5>
                <p className="text-center">
                  {t("FaleMain.ticketInquiry")}
                  <Link
                    onClick={() => pageFaqs("Ingresso-Produtor")}
                    style={{ fontWeight: "bold", marginLeft: 5 }}
                  >
                    {t("FaleMain.frequentQuestions")}
                  </Link>
                </p>
                <div
                  className="f-question"
                  onClick={() => pageFaqs("Ingresso-Produtor")}
                >
                  <div className="row" style={{ cursor: "pointer" }}>
                    <div className="col-md-2 col-lg-2 col-xl-2 text-center">
                      <img src={question} alt="" />
                    </div>
                    <div className="col-md-8 col-lg-8 col-xl-8 text-center">
                      <p>
                        <b>{t("Footer.faq")}</b>
                        <br />
                        <small>{t("FaleMain.forProducerAndEvent")}</small>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="f-question" style={{ marginTop: 20 }}>
                  <div className="row" style={{ cursor: "pointer" }}>
                    <div className="col-md-2 col-lg-2 col-xl-2 text-center">
                      <img src={send} alt="" />
                    </div>
                    <div
                      className="col-md-8 col-lg-8 col-xl-8 text-center"
                      onClick={() => redirect("produtor")}
                    >
                      <p>
                        <b>{t("FaleMain.contactForm")}</b>
                        <br />
                        <small>{t("FaleMain.return")}</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="helper-footer" className="row">
        <div className="container text-center">
          <h3>{t("Footer.faq")}</h3>

          {allFaqs.map((fac) => (
            <div
              className="col-12 blocks"
              key={fac.id}
              onClick={() => redirectSingleFac(fac.id)}
            >
              <p>{fac.question}</p>
              <img src={skip} alt="" />
            </div>
          ))}

          <div className="col-12 blocks-btn">
            <button className="btn" onClick={() => pageFaqs()}>
              {t("FaleMain.viewAllArticles")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
