import React, { useEffect, useState } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";

export default function UserTerms() {
  const [oculta, setOculta] = useState(false);
  const { t } = useTranslation();

  function setTerms() {
    setOculta(true);
    sessionStorage.setItem("Terms", "open");
  }

  function verification() {
    let termo = sessionStorage.getItem("Terms");
    if (termo) {
      setOculta(true);
    } else {
      setOculta(false);
    }
  }
  useEffect(() => {
    verification();
  }, []);
  return (
    !oculta && (
      <div className="container-fluid mb-2" id="box-terms">
        <div className="container shadow" id="info-terms">
          <i
            style={{ cursor: "pointer" }}
            className="fas fa-times"
            onClick={(e) => setTerms()}
          ></i>
          <b>{t("TermsUserAccept.privacyThought")}</b>
          <br></br>
          <p>
            {t("TermsUserAccept.cookiesPolicy")}{" "}
            <a href="/politicas" target="_blank">
              {t("TermsUserAccept.cookiesPolicyLink")}
            </a>{" "}
            {t("TermsUserAccept.privacyPolicy")}{" "}
            <a href="/cookies" target="_blank">
              {t("TermsUserAccept.privacyPolicyLink")}
            </a>
          </p>
        </div>
      </div>
    )
  );
}
