export function removeCheckoutData() {
  let params = window.location.pathname;
  let verify = params.split("/");
  if (verify.length > 0 && verify) {
    if (verify[1] !== "checkout") {
      localStorage.removeItem("order_token");
    }
  }
}

export const calculateValueDiscount = (discountType, discountValue, total) => {
  if (discountType === 1) {
    return (100 * total) / (100 - discountValue);
  } else if (discountType === 0) {
    return total + discountValue;
  }

  return total;
};

export const calculateFeeDiscount = (
  discountType,
  discountValue,
  ticketValue,
  feeValue
) => {
  if (discountType === 1) {
    return (100 * feeValue) / (100 - discountValue);
  } else if (discountType === 0) {
    return (feeValue / ticketValue) * (ticketValue + discountValue);
  }

  return feeValue;
};

export const calculateDiscountTotal = (
  discountType,
  discountValue,
  ticketValue,
  feeValue
) => {
  const total = ticketValue + feeValue;

  if (discountType === 1) {
    return (100 * total) / (100 - discountValue) - total;
  } else if (discountType === 0) {
    return (
      calculateValueDiscount(discountType, discountValue, ticketValue) +
      calculateFeeDiscount(discountType, discountValue, ticketValue, feeValue) -
      total
    );
  }

  return 0;
};
