import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { RecoilRoot } from "recoil";
import * as Sentry from "@sentry/react";
import { version } from "../package.json";
import "./i18n";

const dsn = process.env.REACT_APP_SENTRY_DSN;
const environment = process.env.REACT_APP_SENTRY_ENVIRONMENT;

Sentry.init({
  dsn,
  release: version,
  environment,
  integrations: [new Sentry.Replay()],
  ignoreErrors: [
    "Error: Request failed with status code 404",
    "Error: Cannot find module './en'",
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
});

ReactDOM.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>,
  document.getElementById("root")
);
