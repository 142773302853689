import React from "react";

import Banner from "../Banner";
import { Carousel } from "react-bootstrap";

import "./styles.css";

function Carrousel({ highlights }) {
  return (
    <>
      <Carousel>
        {highlights.map((highlight, index) => (
          <Carousel.Item key={index}>
            {highlight.event && (
              <Banner event={highlight.event} isCarousel={true} />
            )}
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
}

export default Carrousel;
