/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React from "react";
import EventGridContainer from "../Events/EventGridContainer";
import { useTranslation } from "react-i18next";

PaginatedEvents.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      thumb: PropTypes.string,
      is_online: PropTypes.bool,
      event_location: PropTypes.object,
    })
  ),
  isLoading: PropTypes.bool,
  showLoadMore: PropTypes.bool,
};

export default function PaginatedEvents({
  events,
  isLoading,
  showLoadMore,
  onLoadMore,
}) {
  const { t } = useTranslation();
  return (
    <>
      {events?.length > 0 && (
        <div id="near-events" className="container">
          <div className="event-list">
            <div className="event-list-row">
              <EventGridContainer events={events} />
            </div>
          </div>

          {showLoadMore && (
            <button
              type="button"
              className="btn"
              id="more-events"
              onClick={onLoadMore}
              disabled={isLoading}
            >
              {isLoading
                ? t("PaginatedEvents.loadingEvents")
                : t("PaginatedEvents.seeMoreEvents")}
            </button>
          )}
        </div>
      )}
    </>
  );
}
