import React from "react";
import { ReactComponent as GoogleWidget } from "../../assets/svg/widget-google.svg";
import { ReactComponent as AppleWidget } from "../../assets/svg/widget-apple.svg";
import { useTranslation } from "react-i18next";

const DownloadAppFooter = () => {
  const { t } = useTranslation();

  const handleRedirect = () => {
    window.location.href = "https://zigpay.page.link/download";
  };
  return (
    <div className="d-flex flex-md-row flex-column-reverse">
      <div
        className="d-flex flex-sm-column flex-wrap widgets-container"
        style={{
          gap: "10px",
          fontSize: "16px",
          fontWeight: 700,
        }}
      >
        {t("Footer.downloadOurApp")}
        <div className="d-flex flex-row flex-lg-column gap-3 button-container">
          <GoogleWidget className="download-button" onClick={handleRedirect} />
          <AppleWidget className="download-button" onClick={handleRedirect} />
        </div>
      </div>
    </div>
  );
};

export default DownloadAppFooter;
