import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faClock } from "@fortawesome/free-solid-svg-icons";
import CountDown from "../CountDown";
import "./styles.css";
import { useTranslation } from "react-i18next";
function SeatsPageHeader({ event, holdToken, isOpen, onOpen, onShowCart }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { t } = useTranslation();
  const isMobile = windowWidth <= 425;

  const handleGoBack = () => {
    if (isOpen && isMobile) {
      onOpen(false);
    } else {
      onShowCart(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="seats-page-header position-relative d-flex align-items-center w-100 shadow-sm">
      <div className="w-100 px-sm-1 px-md-4 justify-content-between">
        <div className="row justify-content-between align-items-center w-100">
          <div className="col col-2 col-md-2 col-lg-2 justify-content-start">
            <button className="btn" onClick={handleGoBack}>
              <FontAwesomeIcon
                icon={faArrowCircleLeft}
                transform="grow-5"
                color="#12263F"
              />
            </button>
          </div>
          <div className="col-5 col-md-5 col-lg-5 justify-content-center text-center">
            <span className="seats-page-title text-center">{event.name}</span>
          </div>
          <div className="col col-3 col-md-3 col-md-3 d-flex justify-content-end align-items-center">
            <span className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faClock}
                transform="grow-4"
                color="#12263F"
              />
              <p className="seats-page-remaining-time text-end d-none d-md-flex d-sm-none d-lg-flex mb-0 ml-2">
                {t("SeatsHeader.sessionRemainingTime")}
              </p>
              <span className="time text-end ml-1">
                {holdToken && (
                  <CountDown
                    timeToExpire={holdToken.expiresInSeconds}
                    expiresIn={holdToken.expiresAt}
                    onFinishTimer={() => onShowCart(false)}
                  />
                )}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

SeatsPageHeader.propTypes = {
  event: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  holdToken: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
};

export default SeatsPageHeader;
