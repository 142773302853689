import { useRecoilState } from "recoil";
import { authStore } from "../atoms/auth";
import KondutoService from "../services/konduto";
import NewApi from "../services/new-api";
import { usePreferences } from "./usePreferences";
import { pushGAEvent } from "../Helpers/tagManager";
import dayjs from "dayjs";
import MoengageService from "../services/moengage";

export const useAuth = () => {
  const { setFavoriteEvents } = usePreferences();
  const [auth, setAuth] = useRecoilState(authStore);

  const api = new NewApi();

  const initialize = async () => {
    const searchParams = new URLSearchParams(window.location.search);

    const urlToken = searchParams.get("access_token");
    const token = localStorage.getItem("token");

    if (!!urlToken) {
      localStorage.setItem("token", urlToken);
    }

    if (token || !!urlToken) {
      setAuth({
        user: null,
        isLoggingIn: true,
        isInitialized: false,
      });

      try {
        const { data } = await api.get("users/me");
        setAuth({
          user: data,
          isLoggingIn: false,
          isInitialized: true,
        });

        setFavoriteEvents(data.favorite_events.map(({ event_id }) => event_id));
      } catch {
        handleToClearData();
        setAuth({ user: null, isLoggingIn: false, isInitialized: true });
      }
    } else {
      setAuth({ user: null, isLoggingIn: false, isInitialized: true });
    }
  };

  const handleSocialAuth = async (providerParams, provider) => {
    if (providerParams.includes("code")) {
      setAuth((auth) => ({
        ...auth,
        isLoggingIn: true,
      }));

      const result = await api.get(
        `auth/${provider}/callback${providerParams}`
      );

      const {
        user,
        token: { token },
      } = result.data;

      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);

      setAuth((auth) => ({
        ...auth,
        user,
        isInitialized: true,
        isLoggingIn: false,
      }));

      if (dayjs().diff(dayjs(user.created_at), "hours").hours <= 0) {
        pushGAEvent("sign_up", { method: provider });

        if (user) {
          await MoengageService.createEvent({
            type: "event",
            customer_id: user.id,
            actions: [
              {
                action: "sign_up",
                attributes: {
                  ...user,
                },
                platform: "web",
              },
            ],
          });
        }
      }

      pushGAEvent("login", { method: provider });

      if (user) {
        await MoengageService.createEvent({
          type: "event",
          customer_id: user.id,
          actions: [
            {
              action: "login",
              attributes: {
                ...user,
              },
              platform: "web",
            },
          ],
        });
      }

      KondutoService.setCustomerID(user.email);

      if (user) {
        await MoengageService.trackUser({
          type: "customer",
          customer_id: user.id,
          attributes: {
            ...user,
            platforms: [{ platform: "web", active: true }],
          },
        });
      }

      return true;
    } else {
      setAuth({ user: null, isLoggingIn: false, isInitialized: true });
    }

    return false;
  };

  const handleLogin = async (params) => {
    setAuth((prevAuth) => ({
      ...prevAuth,
      isLoggingIn: true,
    }));

    try {
      const tokenResponse = await api.post("auth/token", params);
      localStorage.setItem("token", tokenResponse.data.token);

      const { data } = await api.get("users/me");

      KondutoService.setCustomerID(data.email);

      setAuth({
        user: data,
        isLoggingIn: false,
        isInitialized: true,
      });

      if (data) {
        await MoengageService.trackUser({
          type: "customer",
          customer_id: data.id,
          attributes: {
            ...data,
            platforms: [{ platform: "web", active: true }],
          },
        });
      }

      pushGAEvent("login", { method: "Account" });

      if (data) {
        await MoengageService.createEvent({
          type: "event",
          customer_id: data.id,
          actions: [
            {
              action: "login",
              attributes: {
                ...data,
              },
              platform: "web",
            },
          ],
        });
      }

      setFavoriteEvents(data.favorite_events.map(({ event_id }) => event_id));

      localStorage.setItem("user", JSON.stringify(data));
    } catch (error) {
      setAuth({ user: null, isLoggingIn: false, isInitialized: true });
      throw error;
    }
  };

  const handleLogout = () => {
    handleToClearData();
    KondutoService.setCustomerID("-1");
    // TODO - Add logout event to Moengage
    setAuth({ user: null, isLoggingIn: false, isInitialized: true });
  };

  const handleCompleteRegister = async () => {
    try {
      const { data } = await api.get("users/me");

      setAuth((previous) => ({
        ...previous,
        user: data,
      }));
    } catch {
      handleToClearData();
      setAuth({ user: null, isLoggingIn: false, isInitialized: true });
    }
  };

  const redirectToSocialAuth = async (provider, nextTo = "") => {
    setAuth((prevAuth) => ({
      ...prevAuth,
      isLoggingIn: true,
    }));

    try {
      const { data: socialCallbackUrl } = await api.get(`auth/${provider}`);

      localStorage.setItem("auth_next_to", nextTo);

      window.location.href = socialCallbackUrl;
    } catch (error) {
      setAuth({ user: null, isLoggingIn: false, isInitialized: true });
      throw error;
    }
  };

  const handleRefreshToken = (accessToken) => {
    localStorage.setItem("token", accessToken);
  };

  const handleToClearData = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };

  return {
    user: auth.user,
    isLogged: !!auth.user && auth.isInitialized,
    isLoggingIn: auth.isLoggingIn,
    isInitialized: auth.isInitialized,
    isCompletedRegister:
      auth.user?.complete_register &&
      auth.user?.document &&
      auth.user?.first_name &&
      auth.user?.last_name &&
      auth.user?.birthdate,
    initialize,
    login: handleLogin,
    logout: handleLogout,
    updateToken: handleRefreshToken,
    completeRegister: handleCompleteRegister,
    socialAuth: redirectToSocialAuth,
    processSocialAuth: handleSocialAuth,
  };
};
