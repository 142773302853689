import React, { Fragment, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import TicketNameView from "./TicketNameView";
import MaskHelper from "../../Helpers/mask";
import { calculateValueDiscount } from "../../Helpers/checkout";
import { useTranslation } from "react-i18next";

const TicketInfoView = ({
  ticket,
  quantity,
  singleLine,
  showDivider,
  discountInfo,
  addTicketFee,
  showQuantity,
  showPriceDetails,
}) => {
  const itemsClassName = useMemo(
    () => `order-items ${showDivider ? "" : "order-items-without-divider"}`,
    [showDivider]
  );
  const { t } = useTranslation();

  return (
    <Row className={itemsClassName}>
      <Col xs={8} className={singleLine ? "order-items-fixed-line" : ""}>
        <span className={showPriceDetails ? "font-weight-bold" : ""}>
          {showQuantity ? `${quantity}x ` : ""}
          <TicketNameView ticket={ticket} />
        </span>

        {showPriceDetails && (
          <>
            <br />

            {ticket.value === 0 && ticket.fee === 0 ? (
              <span>{t("TicketInfoView.free")}</span>
            ) : (
              <Fragment>
                {ticket.fee > 0 && (
                  <span>
                    {MaskHelper.numberToLocaleCurrency(ticket.value) + " "}({" "}
                    {t("TicketInfoView.fee")}
                    {MaskHelper.numberToLocaleCurrency(ticket.fee)})
                  </span>
                )}
              </Fragment>
            )}
          </>
        )}
      </Col>

      <Col className="text-right">
        {ticket.value === 0 && ticket.fee === 0
          ? t("TicketInfoView.free")
          : MaskHelper.numberToLocaleCurrency(
              (discountInfo
                ? calculateValueDiscount(
                    discountInfo.type,
                    discountInfo.value,
                    ticket.value + (addTicketFee ? ticket.fee : 0)
                  )
                : ticket.value + (addTicketFee ? ticket.fee : 0)) * quantity
            )}
      </Col>
    </Row>
  );
};

export default TicketInfoView;
