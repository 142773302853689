import React, { Fragment } from "react";
import { ReactComponent as Export } from "../../../../../assets/svg/external.svg";
import NewButton from "../../../../../components/NewButton";
import { useTranslation } from "react-i18next";

export default function PicpaySection({ code, handleRedirect }) {
  const { t } = useTranslation();
  return (
    <Fragment>
      {code && (
        <div className="d-flex justify-content-center align-items-center">
          <NewButton
            className="secondary-contained"
            onClick={() => handleRedirect("picpay")}
            content={
              <Fragment>
                <Fragment>
                  {t("CheckoutStatus.buttons.picpay")} <Export />
                </Fragment>
              </Fragment>
            }
          />
        </div>
      )}
    </Fragment>
  );
}
