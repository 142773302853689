import Translations from "./translations";

export default class ErrorHandler {
  static handleFormErrors = (error, t) => {
    let message = t("CommonExpressions.unexpectedError");

    if (error) {
      if (error.errors) {
        for (const key of Object.keys(error.errors)) {
          const items = [];

          error.errors[key].forEach((message) =>
            items.push(
              `${Translations.fields(t)[key]} ${
                Translations.messages(t)[message]
              }`
            )
          );

          message = items.join("<br/>");
        }
      } else if (error.message) {
        message = error.message;
      }
    }

    return message;
  };
}
