import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import userLogo from "../../assets/img/userLogo.png";
import { useAuth } from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const nomeEmpresa = process.env.REACT_APP_NAME_EMPRESA;

function LoginMethods({ show, nextToUrl, onClose, onClickEmail }) {
  const { socialAuth } = useAuth();
  const { t } = useTranslation();
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isInstagram = userAgent.indexOf("Instagram") > -1 ? true : false;

  return (
    <Fragment>
      <Modal show={show} onHide={onClose} id="login-methods-modal">
        <Modal.Header className="new-modal-header">
          <img alt="" src={userLogo} />
          <Modal.Title className="new-modal-title">
            {t("LoginMethods.accessYourAccount")}
          </Modal.Title>
          <p className="modal-p">{t("LoginMethods.connectWithSocial")}</p>
        </Modal.Header>
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="close-icon-login"
          onClick={onClose}
        />
        <Modal.Body className="body">
          <div className="d-flex flex-column justify-content-between">
            {!!process.env.REACT_APP_ENABLE_GOOGLE_SSO && !isInstagram && (
              <button
                type="button"
                className="btn btn-outline-secondary mb-2"
                onClick={() => socialAuth("google", nextToUrl)}
              >
                <img
                  className="fab fa-google"
                  src="/Google.svg"
                  alt="Google"
                ></img>{" "}
                {t("LoginMethods.continueWithGoogle")}
              </button>
            )}
            {!!process.env.REACT_APP_ENABLE_FB_SSO && (
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => socialAuth("facebook", nextToUrl)}
              >
                <i
                  className="fab fa-facebook-square"
                  style={{ color: "#3b5998" }}
                ></i>{" "}
                {t("LoginMethods.continueWithFacebook")}
              </button>
            )}
            <div
              style={{ border: "1px solid rgba(0,0,0,.1)" }}
              className="mb-3 mt-3"
            ></div>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={onClickEmail}
            >
              <i className="far fa-envelope"></i>{" "}
              {t("LoginMethods.continueWithEmail")}
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="new-modal-footer">
          <div className="w-100">
            <hr />
            <p className="text-center p-register">
              {t("LoginMethods.termsOfUseAcceptance")}
              <br />
              <a href="/termos" target="_blank">
                {t("Footer.termsOfUse")}
              </a>{" "}
              {t("CommonExpressions.of")} {nomeEmpresa}
            </p>
          </div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default LoginMethods;
