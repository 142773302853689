import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import MaskHelper from "../../../Helpers/mask";
import { FillWithMyData } from "./FillWithMyData";

export const BuyerDataInformationView = ({
  user,
  errors,
  register,
  translate,
  onSetValue,
  clearErrors,
  onZipCodeFetched,
}) => {
  return (
    <div>
      <FillWithMyData
        clearErrors={clearErrors}
        onSetValue={onSetValue}
        onZipCodeFetched={onZipCodeFetched}
        translate={translate}
        user={user}
      />
      <Row>
        <Col md={6} className="mb-2">
          <Form.Group>
            <Form.Label htmlFor="document">
              <span className="text-danger">*</span>{" "}
              {translate("Checkout.twoDocuments")}
            </Form.Label>
            <Form.Control
              id="document"
              type="text"
              className={errors.payment?.document && "is-invalid"}
              {...register("payment.document")}
              onChange={(e) =>
                onSetValue(
                  "payment.document",
                  MaskHelper.brazilianDocumentMask(e.target.value)
                )
              }
            />
            {errors.payment?.document && (
              <Form.Text className="error-message">
                {errors.payment?.document?.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
        <Col md={6} className="mb-2">
          <Form.Group>
            <Form.Label htmlFor="phone">
              <span className="text-danger">*</span>
              {translate("Checkout.phone")}
            </Form.Label>
            <Form.Control
              id="phone"
              type="text"
              className={errors.payment?.phone && "is-invalid"}
              {...register("payment.phone")}
              onChange={(e) =>
                onSetValue(
                  "payment.phone",
                  MaskHelper.phoneMask(e.target.value)
                )
              }
            />
            {errors.payment?.phone && (
              <Form.Text className="error-message">
                {errors.payment?.phone?.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};
