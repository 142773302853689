import { Col, Form } from "react-bootstrap";
import React from "react";
import "./index.css";

const AttendeeQuestionView = ({
  id,
  name,
  field,
  value,
  errors,
  register,
  onChange,
}) => {
  const {
    required,
    field_list,
    description,
    field_type: { type },
  } = field;

  const answersOptions = JSON.parse(field_list);
  const isText = ["text", "textarea"].includes(type);

  return (
    <Col xs={12} key={id} className="attendee-question-container">
      <Form.Group className={errors && !isText ? "is-invalid-group" : ""}>
        <Form.Label htmlFor={id}>
          <span className="text-danger">{required ? "*" : ""}</span>
          {description}
        </Form.Label>

        {isText && (
          <Form.Control
            id={id}
            as={type === "text" ? "input" : type}
            className={errors && "is-invalid"}
            value={value}
            {...register(name)}
          />
        )}

        {type === "select" && (
          <Form.Control
            id={id}
            as="select"
            name={name}
            defaultValue={value}
            {...register(name)}
          >
            <>
              {Object.entries(answersOptions?.option).map(([key, value]) => (
                <option key={key} value={value}>
                  {value}
                </option>
              ))}
            </>
          </Form.Control>
        )}

        {["checkbox", "radiobutton"].includes(type) && !!answersOptions && (
          <Form.Group className="lightWeightFont">
            {Object.entries(answersOptions?.option).map(([key, answer]) => (
              <Form.Check
                id={`${id}-${key}`}
                key={key}
                type={type === "radiobutton" ? "radio" : "checkbox"}
                label={answer}
                value={answer}
                checked={
                  type === "radiobutton"
                    ? typeof value === "string"
                      ? answer === value
                      : (Array.isArray(value) ? value : []).find(
                          (item) => item === answer
                        )
                    : (Array.isArray(value) ? value : []).find(
                        (item) => item === answer
                      )
                }
                {...register(name)}
              />
            ))}
          </Form.Group>
        )}
      </Form.Group>

      {!!errors && (
        <Form.Text className="error-message">{errors.message}</Form.Text>
      )}
    </Col>
  );
};

export default AttendeeQuestionView;
