import React from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function EventLoader() {
  const { t } = useTranslation();

  return (
    <div className="event-loader">
      <div className="loader-icon">
        <FontAwesomeIcon icon={faCircleNotch} id="loading-icon" />
      </div>
      <h4>{t("Index.wait")}</h4>
      <p>{t("Index.loading")}</p>
    </div>
  );
}
